import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { STORE_DATA_CRAFTER_TEMPLATES } from '../stores/constants';
import { DataCrafterTemplatesStores } from '../stores';
import { DataCrafterTemplatesList } from '../components';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';

const DataCrafterTemplatesListScreen: React.FC<DataCrafterTemplatesStores> = ({ dataCrafterTemplates }) => {
    if (!dataCrafterTemplates) {
        return null;
    }

    return (
        <HasPermission permissionClaim={AppPermissions.CanManageDataCrafterTemplates}>
            <DataCrafterTemplatesList store={dataCrafterTemplates} />
        </HasPermission>
    );
};

export default inject(STORE_DATA_CRAFTER_TEMPLATES)(observer(DataCrafterTemplatesListScreen));
