import * as React from 'react';
import { TestProjectTopicWizardStore } from '../../stores';
import { observer } from 'mobx-react-lite';
import { Select } from 'antd';

type Props = {
    store: TestProjectTopicWizardStore;
};

const TestProjectTopicsAppSelector: React.FC<Props> = ({ store }) => {
    if (store.targetInputType !== 'Bindings') {
        return null;
    }

    return (
        <Select
            style={{ width: '235px', marginRight: '12px' }}
            options={store.iotaApplications.map(app => ({ title: app.name, label: app.name, value: app.id }))}
            showSearch
            value={store.targetIotaApplicationId}
            onChange={value => store.setTargetIotaApplicationId(value)}
            filterOption={(input, option) => option?.title && option.title.toLowerCase().includes(input.toLowerCase())}
            placeholder="Select target application"
        />
    );
};

export default observer(TestProjectTopicsAppSelector);
