import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tree, Button, message, Spin, Tabs, Divider } from 'antd';
import { CopyOutlined, FileOutlined, SyncOutlined } from '@ant-design/icons';
import { DataCrafterTemplateFormStore } from '../../stores';
import { InputPackageSelector } from '..';
import './InputPackageColumnsSelector.less';

type Props = {
    createStore: DataCrafterTemplateFormStore;
};

const InputPackageColumnsSelector: React.FC<Props> = ({ createStore }) => {
    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            message.success('Column name copied to clipboard');
        });
    };

    const handleFetchColumns = () => {
        if (createStore.selectedInputPackages.length === 0) {
            message.warning('Please select at least one input package');
            return;
        }
        createStore.fetchInputColumns();
    };

    // TODO: Change how package selection looks to avoid duplication of information

    // Group columns by package and worksheet
    const tabPanes = React.useMemo(() => {
        const packageGroups = new Map<string, typeof createStore.inputColumns>();
        createStore.inputColumns.forEach(col => {
            if (!packageGroups.has(col.packageId)) {
                packageGroups.set(col.packageId, []);
            }
            packageGroups.get(col.packageId)?.push(col);
        });

        return Array.from(packageGroups.entries()).map(([packageId, columns]) => {
            const worksheets = new Map<string, typeof createStore.inputColumns>();
            columns.forEach(col => {
                if (!worksheets.has(col.worksheetName)) {
                    worksheets.set(col.worksheetName, []);
                }
                worksheets.get(col.worksheetName)?.push(col);
            });

            const treeData = Array.from(worksheets.entries()).map(([worksheet, worksheetColumns]) => ({
                title: worksheet,
                key: `${packageId}-${worksheet}`,
                children: worksheetColumns.map(col => ({
                    title: (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>{col.name}</span>
                            <span style={{ color: '#999', marginLeft: 8 }}>({col.headerCoordinates})</span>
                            <Button
                                type="text"
                                icon={<CopyOutlined />}
                                onClick={e => {
                                    e.stopPropagation();
                                    handleCopy(`${col.worksheetName}:${col.name}:${col.headerCoordinates}`);
                                }}
                                size="small"
                            />
                        </div>
                    ),
                    key: `${packageId}-${worksheet}-${col.name}-${col.headerCoordinates}`
                }))
            }));

            const pkg = createStore.packages.find(p => p.id === packageId);
            const tabName = (
                <>
                    <FileOutlined />
                    {pkg?.name || packageId}
                </>
            );
            return (
                <Tabs.TabPane key={packageId} tab={tabName}>
                    <Tree className="columns-tree" treeData={treeData} defaultExpandAll showLine />
                </Tabs.TabPane>
            );
        });
    }, [createStore.inputColumns, createStore.packages]);

    return (
        <div className="input-package-columns-selector">
            <div className="package-selector-section">
                <InputPackageSelector
                    packages={createStore.packages}
                    isLoadingPackages={createStore.isLoadingPackages}
                    value={createStore.selectedInputPackages}
                    onChange={value => createStore.setSelectedInputPackages(value)}
                    placeholder="Select input packages"
                />
                <Button
                    type="primary"
                    icon={<SyncOutlined spin={createStore.isLoadingInputColumns} />}
                    onClick={handleFetchColumns}
                    loading={createStore.isLoadingInputColumns}
                    disabled={createStore.selectedInputPackages.length === 0}
                >
                    Fetch Columns
                </Button>
            </div>
            {tabPanes.length > 0 && <Divider />}
            <Spin spinning={createStore.isLoadingInputColumns}>{tabPanes.length > 0 && <Tabs>{tabPanes}</Tabs>}</Spin>
        </div>
    );
};

export default observer(InputPackageColumnsSelector);
