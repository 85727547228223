import { action, computed, observable, runInAction } from 'mobx';
import { ProjectsRootVisualStore, RouterStore, UsersStore } from '../../common/stores';
import { DataCrafterTemplatesService } from '../services';
import { DataCrafterTemplate, DataCrafterTemplateStatus } from '../types';
import { DataCrafterTemplatesNavigation } from '../routes';
import { message } from 'antd';
import { Package, PackageState } from '../../common/models';
import { ProjectsService } from '../../common/services';
import { SearchPackagesRequest } from '../../common/types';
import { PAGE_SIZE } from '../../common/stores/Pager';

export default class DataCrafterTemplatesVisualStore {
    @observable
    templates: DataCrafterTemplate[] = [];

    @observable
    isLoadingTemplates: boolean = false;

    @observable
    changingStatusTemplateIds: string[] = [];

    @observable
    packages: Package[] = [];

    @observable
    loadingPackages: boolean = false;

    @observable
    selectedTemplateId: string | null = null;

    @observable
    isDuplicating: boolean = false;

    @observable
    templateToDuplicate: string | null = null;

    @computed
    get currentProject() {
        return this.projectsStore.currentProject;
    }

    @computed
    get isLoadingPackages(): boolean {
        return this.loadingPackages;
    }

    @computed
    get sourceTemplateName(): string | null {
        if (!this.templateToDuplicate) return null;
        const template = this.templates.find(t => t.id === this.templateToDuplicate);
        return template ? template.name : null;
    }

    constructor(
        private projectsStore: ProjectsRootVisualStore,
        private dataCrafterTemplatesService: DataCrafterTemplatesService,
        private routerStore: RouterStore,
        private projectsService: ProjectsService,
        private usersStore: UsersStore
    ) {}

    @action.bound
    getUserNameById(id: string) {
        return this.usersStore.getUserNameById(id);
    }

    @action.bound
    setIsLoadingTemplates(isLoading: boolean) {
        this.isLoadingTemplates = isLoading;
    }

    @action.bound
    async loadAllProjectPackages() {
        if (!this.currentProject) {
            return;
        }

        try {
            runInAction(() => {
                this.loadingPackages = true;
            });

            let request: SearchPackagesRequest = {
                page: 0,
                pageSize: PAGE_SIZE,
                projectId: this.currentProject.id,
                allSources: true,
                uploadedBy: this.projectsStore.hasAccessToAllEntities ? undefined : this.projectsStore.currentUserId,
                state: [PackageState.Ready]
            };
            const firstResponse = await this.projectsService.searchPackages(this.currentProject, request);

            runInAction(() => {
                this.packages = firstResponse.lines;
            });

            if (firstResponse.lines.length < firstResponse.total) {
                const pages = Math.ceil(firstResponse.total / PAGE_SIZE);
                for (let i = 1; i < pages; i++) {
                    request.page = i;
                    const response = await this.projectsService.searchPackages(this.currentProject, request);
                    runInAction(() => {
                        this.packages = this.packages.concat(response.lines);
                    });
                }
            }
        } catch (err) {
            console.error(err);
            message.error(err);
        } finally {
            runInAction(() => {
                this.loadingPackages = false;
            });
        }
    }

    @action.bound
    async loadTemplates() {
        if (!this.currentProject) {
            return;
        }

        this.setIsLoadingTemplates(true);
        try {
            const templates = await this.dataCrafterTemplatesService.getTemplates(this.currentProject.id);
            runInAction(() => {
                this.templates = templates;
            });
        } catch (err) {
            message.error('Failed to load output file templates');
            console.error(err);
        } finally {
            this.setIsLoadingTemplates(false);
        }
    }

    @action.bound
    async changeTemplateStatus(
        templateId: string,
        status: DataCrafterTemplateStatus
    ): Promise<DataCrafterTemplate | null> {
        if (!this.currentProject) {
            return null;
        }

        try {
            runInAction(() => {
                this.changingStatusTemplateIds = [...this.changingStatusTemplateIds, templateId];
            });

            const template = await this.dataCrafterTemplatesService.changeTemplateStatus(
                this.currentProject.id,
                templateId,
                { status }
            );

            if (!template) {
                message.error('Failed to update output file template status');
                return null;
            }

            runInAction(() => {
                const clonedTemplates = [...this.templates];
                const index = clonedTemplates.findIndex(t => t.id === templateId);
                if (index !== -1) {
                    clonedTemplates[index] = template;
                }
                this.templates = clonedTemplates;
            });
            message.success('Output file template status updated successfully');
            return template;
        } catch (err) {
            message.error('Failed to update output file template status');
            console.error(err);
            return null;
        } finally {
            runInAction(() => {
                this.changingStatusTemplateIds = this.changingStatusTemplateIds.filter(id => id !== templateId);
            });
        }
    }

    @action.bound
    async deleteTemplate(templateId: string): Promise<boolean> {
        if (!this.currentProject) {
            return false;
        }

        try {
            const success = await this.dataCrafterTemplatesService.deleteTemplate(this.currentProject.id, templateId);
            if (success) {
                runInAction(() => {
                    this.templates = this.templates.filter(t => t.id !== templateId);
                });
                message.success('Output file template deleted successfully');
            }
            return success;
        } catch (err) {
            message.error('Failed to delete output file template');
            console.error(err);
            return false;
        }
    }

    @action.bound
    async archiveTemplate(templateId: string): Promise<boolean> {
        if (!this.currentProject) {
            return false;
        }

        try {
            const success = await this.dataCrafterTemplatesService.deleteTemplate(this.currentProject.id, templateId);
            if (success) {
                runInAction(() => {
                    this.templates = this.templates.filter(t => t.id !== templateId);
                });
                message.success('Template archived successfully');
            }
            return success;
        } catch (err) {
            message.error('Failed to archive template');
            console.error(err);
            return false;
        }
    }

    @action.bound
    navigateToCreateTemplate() {
        if (!this.currentProject) {
            return;
        }

        this.routerStore.push(
            DataCrafterTemplatesNavigation.CreateDataCrafterTemplateScreen.replace(':projectId', this.currentProject.id)
        );
    }

    @action.bound
    navigateToList() {
        if (!this.currentProject) {
            return;
        }

        this.routerStore.push(
            DataCrafterTemplatesNavigation.DataCrafterTemplatesScreen.replace(':projectId', this.currentProject.id)
        );
    }

    @action.bound
    navigateToEditTemplate(templateId: string) {
        if (!this.currentProject) {
            return;
        }

        this.selectedTemplateId = templateId;
        this.routerStore.push(
            DataCrafterTemplatesNavigation.EditDataCrafterTemplateScreen.replace(
                ':projectId',
                this.currentProject.id
            ).replace(':templateId', templateId)
        );
    }

    @action.bound
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    matchCurrentTemplateData(params: any) {
        this.selectedTemplateId = params.templateId;
    }

    @action.bound
    setTemplateToDuplicate(templateId: string | null) {
        this.templateToDuplicate = templateId;
    }

    @action.bound
    async duplicateTemplate(newName: string): Promise<boolean> {
        if (!this.currentProject || !this.templateToDuplicate) {
            return false;
        }

        try {
            runInAction(() => {
                this.isDuplicating = true;
            });

            const result = await this.dataCrafterTemplatesService.duplicateTemplate(
                this.currentProject.id,
                this.templateToDuplicate,
                newName
            );

            if (result) {
                message.success('Template duplicated successfully');
                await this.loadTemplates();
                return true;
            } else {
                message.error('Failed to duplicate template');
                return false;
            }
        } catch (error) {
            console.error('Failed to duplicate template:', error);
            message.error('Failed to duplicate template');
            return false;
        } finally {
            runInAction(() => {
                this.isDuplicating = false;
                this.templateToDuplicate = null;
            });
        }
    }
}
