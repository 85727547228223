import { Button, Col, Collapse, Input, Row, Select } from 'antd';
import { runInAction } from 'mobx';
import Form, { FormInstance } from 'antd/lib/form';
import { inject } from 'mobx-react';
import { observer, useLocalStore } from 'mobx-react-lite';
import * as React from 'react';
import { RuleEditGeneralSettings } from '..';
import { STORE_RULE_EDIT } from '../../constants';
import SmartIndexRule from '../../models/SmartIndexRule';
import { RuleEditVisualStore } from '../../stores';
import RuleType from '../../types/RuleType';
import InstructWorkflowService from '../../../instruct_workflows/services/InstructWorkflowService';
import { InstructWorkflowDto } from '../../../instruct_workflows/types';
import Checkbox from 'antd/lib/checkbox';
import { WorkflowTextInputRowEntry } from '../../types';
import InstructWorkflowStore from '../../../instruct_workflows/stores/InstructWorkflowStore';
import WorkflowTextInputRow from '../WorkflowTextInputRow/WorkflowTextInputRow';
import './RuleEditSmartIndexForm.less';

type Props = {
    store: RuleEditVisualStore;
    workflowStore: InstructWorkflowStore;
    selectType?: (type: RuleType) => void;
    form: FormInstance;
};

const RuleEditSmartIndexForm: React.FC<Props> = ({ store, workflowStore, selectType, form }) => {
    const rule = store.editableRule as SmartIndexRule;
    const [selectedWorkflowId, setSelectedWorkflowId] = React.useState<string>(rule.instructWorkflowId);
    const [openPanelKeys, setOpenPanelKeys] = React.useState<string[]>([]);
    const [shouldOverrideParams, setShouldOverrideParams] = React.useState<boolean>(rule.overrideWorkflowParameters);

    const workflow = React.useMemo(
        () => workflowStore.workflows.find(f => f.id === selectedWorkflowId),
        [workflowStore.workflows, selectedWorkflowId]
    );

    const localState = useLocalStore(() => ({
        loading: false,
        service: new InstructWorkflowService(),
        workflows: new Array<InstructWorkflowDto>(),
        workFlowParams: new Array<WorkflowTextInputRowEntry>(),
        async load() {
            if (!store.currentProject) {
                return;
            }

            runInAction(() => {
                localState.loading = true;
            });

            const workflows = await this.service.getWorkflows(store.currentProject.id);

            runInAction(() => {
                localState.workflows = workflows;
                localState.loading = false;
            });
        },
        addWorkFlowParameter() {
            const newParam: WorkflowTextInputRowEntry = {
                id: '',
                name: '',
                isCustom: true,
                value: ''
            };
            this.workFlowParams.push(newParam);
            form.setFieldsValue({ overriddenWorkflowParameters: [...this.workFlowParams, newParam] });
        },
        setWorkFlowParams(params: WorkflowTextInputRowEntry[]) {
            this.workFlowParams = params;
            form.setFieldsValue({ overriddenWorkflowParameters: params });
        }
    }));

    React.useEffect(() => {
        runInAction(() => {
            const overriddenParams = rule.overriddenWorkflowParameters ?? {};
            const currentWorkflow = workflowStore.workflows.find(f => f.id === selectedWorkflowId);

            if (!currentWorkflow) {
                return;
            }
            const textInputs = currentWorkflow!.workflowType?.textInputs;

            const params = [
                ...(textInputs?.map(textInput => {
                    const { id, name, defaultValue, description } = textInput;
                    const inputValue = currentWorkflow!.inputValues?.find(input => input.inputId === id)?.value;
                    const paramValue = overriddenParams[id] ?? inputValue;
                    return {
                        id,
                        name,
                        value: paramValue ?? defaultValue ?? null,
                        isCustom: false,
                        description
                    };
                }) ?? []),
                ...Object.keys(overriddenParams)
                    .filter(key => !textInputs?.some(textInput => textInput.id === key))
                    .map(key => ({
                        id: key,
                        name: '',
                        value: overriddenParams[key],
                        isCustom: true,
                        description: ''
                    }))
            ];

            localState.setWorkFlowParams(params);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        workflow,
        localState,
        rule.overriddenWorkflowParameters,
        selectedWorkflowId,
        workflowStore.workflows,
        shouldOverrideParams
    ]);

    const onValuesChange = (changedValues: Record<string, unknown>) => {
        if ('instructWorkflowId' in changedValues) {
            form.setFieldsValue({ outputSchemeName: undefined });
            form.setFieldsValue({ overriddenWorkflowParameters: undefined });
            form.resetFields([['overriddenWorkflowParameters']]);
            localState.setWorkFlowParams([]);
            setSelectedWorkflowId(changedValues.instructWorkflowId as string);
        }

        if ('overriddenWorkflowParameters' in changedValues) {
            const updatedParams = localState.workFlowParams.map((param, index) => {
                const changedParam = (changedValues.overriddenWorkflowParameters as WorkflowTextInputRowEntry[])[index];
                if (changedParam) {
                    return {
                        ...param,
                        id: changedParam.id !== undefined ? changedParam.id : param.id,
                        value: changedParam.value !== undefined ? changedParam.value : param.value
                    };
                }
                return param;
            });

            localState.setWorkFlowParams(updatedParams);
        }

        if ('overrideWorkflowParameters' in changedValues) {
            setShouldOverrideParams(changedValues.overrideWorkflowParameters as boolean);
        }
    };

    const getInitialValues = React.useCallback(() => {
        return {
            name: rule.name,
            tag: rule.tag,
            instructWorkflowId: rule.instructWorkflowId,
            priority: rule.priority,
            description: rule.description,
            overridePriority: rule.overridePriority,
            prompt: rule.prompt,
            outputSchemeName: rule.outputSchemeName,
            overrideWorkflowParameters: rule.overrideWorkflowParameters
        };
    }, [rule]);

    React.useEffect(() => {
        const initialValues = getInitialValues();
        form.setFieldsValue(initialValues);
        setSelectedWorkflowId(initialValues.instructWorkflowId);
    }, [form, rule, getInitialValues]);

    React.useEffect(() => {
        localState.load();
    }, [localState]);

    const panelExtra = () => {
        return (
            <div
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <Form.Item name="overrideWorkflowParameters" valuePropName="checked">
                    <Checkbox style={{ marginLeft: 12 }} />
                </Form.Item>
            </div>
        );
    };

    return (
        <>
            {store.error.map((e, i) => (
                <h4 key={e + i} style={{ color: 'red' }}>
                    {e}
                </h4>
            ))}
            <Form
                form={form}
                layout="vertical"
                className="rule-edit-form"
                data-id="rule-edit-form"
                onValuesChange={onValuesChange}
            >
                <RuleEditGeneralSettings store={store} selectType={selectType} form={form} />
                <Row gutter={16}>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            label="Instruct Wokflow"
                            name="instructWorkflowId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Instruct Wokflow!'
                                }
                            ]}
                            data-id="rule-query-input"
                        >
                            <Select loading={localState.loading} allowClear>
                                {localState.workflows.map(f => (
                                    <Select.Option key={f.id} value={f.id}>
                                        {f.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            label="Prompt"
                            name="prompt"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Prompt!'
                                }
                            ]}
                            data-id="rule-query-input"
                        >
                            <Input.TextArea style={{ height: 'unset' }} rows={4} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                        <Collapse
                            activeKey={openPanelKeys}
                            className="workflow-parameters-collapse"
                            onChange={key => setOpenPanelKeys(key as string[])}
                            style={{ marginBottom: '18px' }}
                        >
                            <Collapse.Panel key="1" header="Workflow parameters" forceRender extra={panelExtra()}>
                                <div className="d-flex justify-content-between align-items-center">
                                    {localState.workFlowParams.map((input, index) => (
                                        <WorkflowTextInputRow
                                            key={input.id || index}
                                            input={input}
                                            disabled={!shouldOverrideParams}
                                            index={index}
                                            onItemDelete={() => {
                                                const updatedParams = localState.workFlowParams.filter(
                                                    (_, i) => i !== index
                                                );

                                                localState.setWorkFlowParams(updatedParams);
                                            }}
                                        />
                                    ))}
                                    <Button
                                        onClick={shouldOverrideParams ? localState.addWorkFlowParameter : undefined}
                                        disabled={!shouldOverrideParams}
                                        block
                                    >
                                        + Add Custom Parameter
                                    </Button>
                                </div>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                    <Col className="gutter-row" span={24}>
                        <Form.Item label="Output Scheme" name="outputSchemeName" data-id="rule-query-input">
                            <Select allowClear>
                                {(workflow ? workflow.originalOutputSchemes : []).map((s, i) => (
                                    <Select.Option key={i + s.name} value={s.name}>
                                        {s.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default inject(STORE_RULE_EDIT)(observer(RuleEditSmartIndexForm));
