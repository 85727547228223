import { action, observable } from 'mobx';

export default class DataCrafterTemplateColumn {
    @observable
    mappingRule: string;

    name: string;
    worksheetName: string;
    headerCoordinates: string;
    packageId: string;

    constructor(
        name: string,
        worksheetName: string,
        headerCoordinates: string,
        packageId: string,
        mappingRule: string
    ) {
        this.name = name;
        this.worksheetName = worksheetName;
        this.headerCoordinates = headerCoordinates;
        this.packageId = packageId;
        this.mappingRule = mappingRule;
    }

    @action.bound
    setMappingRule(mappingRule: string) {
        this.mappingRule = mappingRule;
    }
}
