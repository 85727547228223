import { observer } from 'mobx-react-lite';
import * as React from 'react';

type TestProjectProgressSectionProps = {
    startTime?: string;
    endTime?: string;
};

const isValidDate = (date: Date) => !isNaN(date.getTime());

const createDuration = (startTime?: string, endTime?: string): string => {
    if (!startTime || !endTime) {
        return '';
    }

    const start = new Date(startTime);
    const end = new Date(endTime);

    if (!isValidDate(start) || !isValidDate(end)) {
        return '';
    }

    const ms = end.getTime() - start.getTime();

    if (ms < 0) {
        return '';
    }
    const totalMinutes = Math.floor(ms / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (totalMinutes < 1) {
        return '<1 m';
    } else if (totalMinutes < 60) {
        return `${totalMinutes} m`;
    } else {
        return `${hours} h ${minutes} m`;
    }
};

const TestProjectDuration: React.FC<TestProjectProgressSectionProps> = ({ startTime, endTime }) => {
    return <span> {createDuration(startTime, endTime)} </span>;
};

export default observer(TestProjectDuration);
