import React, { useEffect, useState } from 'react';

interface TimeElapsedProps {
    datetime: string;
}

const TimeElapsed: React.FC<TimeElapsedProps> = ({ datetime }) => {
    const [elapsedTime, setElapsedTime] = useState('');

    useEffect(() => {
        const calculateElapsedTime = () => {
            const pastTime = new Date(datetime).getTime();
            const currentTime = Date.now();
            const difference = currentTime - pastTime;

            if (difference < 0) {
                setElapsedTime('0 seconds');
                return;
            }

            const seconds = Math.floor(difference / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            if (days > 0) {
                setElapsedTime(`${days} day${days > 1 ? 's' : ''}`);
            } else if (hours > 0) {
                setElapsedTime(`${hours} hour${hours > 1 ? 's' : ''}`);
            } else if (minutes > 0) {
                setElapsedTime(`${minutes} minute${minutes > 1 ? 's' : ''}`);
            } else {
                setElapsedTime(`${seconds} second${seconds > 1 ? 's' : ''}`);
            }
        };

        calculateElapsedTime();
        const interval = setInterval(calculateElapsedTime, 1000);

        return () => clearInterval(interval);
    }, [datetime]);

    return <span>Running for {elapsedTime}</span>;
};

export default TimeElapsed;
