import React from 'react';

interface CountdownTimerProps {
    milliseconds: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ milliseconds }) => {
    const formatTime = (ms: number): string => {
        if (ms <= 0) {
            return '';
        }
        const seconds = Math.floor(ms / 1000) % 60;
        const minutes = Math.floor(ms / (1000 * 60)) % 60;
        const hours = Math.floor(ms / (1000 * 60 * 60)) % 24;
        const days = Math.floor(ms / (1000 * 60 * 60 * 24));

        const timeUnits = [
            { value: days, label: 'day' },
            { value: hours, label: 'hour' },
            { value: minutes, label: 'minute' },
            { value: seconds, label: 'second' }
        ];

        const nonZeroUnits = timeUnits.filter(unit => unit.value > 0);
        const largestUnits = nonZeroUnits.slice(0, 2);

        if (ms < 1000) {
            return '1 second remaining';
        }

        return (
            largestUnits.map(unit => `${unit.value} ${unit.label}${unit.value > 1 ? 's' : ''}`).join(' ') + ' remaining'
        );
    };

    return <div>{formatTime(milliseconds)}</div>;
};

export default CountdownTimer;
