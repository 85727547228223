import React from 'react';
import { Button, Form, FormInstance, Input, Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { OverridenParamsEditorEntry } from '../../types';
import './WorkflowParameters.less';

type WorkflowParametersProps = {
    overrideEnabled: boolean;
    form: FormInstance;
    overriddenWorkflowParameters?: OverridenParamsEditorEntry[];
    handleRemoveField: (index: number) => void;
    handleAddField: () => void;
};

export const WorkflowParametersList: React.FC<WorkflowParametersProps> = ({
    overrideEnabled,
    form,
    overriddenWorkflowParameters,
    handleRemoveField,
    handleAddField
}) => {
    if (!overriddenWorkflowParameters || !Array.isArray(form.getFieldValue('overridden_workflow_parameters'))) {
        return null;
    }

    return (
        <Form.List name="overridden_workflow_parameters">
            {(fields, { remove }) => (
                <>
                    {fields.map(({ key, name, fieldKey }, index) => {
                        const keyError = form.getFieldError(['overridden_workflow_parameters', name, 'key']);
                        const valError = form.getFieldError(['overridden_workflow_parameters', name, 'value']);
                        const rowErrors = keyError.concat(valError);

                        return (
                            <React.Fragment key={key}>
                                <Space
                                    align="center"
                                    className={`workflow-parameter-row ${rowErrors.length > 0 ? 'error-row' : ''}`}
                                >
                                    <Tooltip title={overriddenWorkflowParameters[index]?.key || ''}>
                                        <div className="form-item-wrapper">
                                            <Form.Item
                                                name={[name, 'key']}
                                                fieldKey={[fieldKey, 'key']}
                                                noStyle
                                                rules={[{ required: true, message: 'Key is required' }]}
                                                className="form-item"
                                            >
                                                <Input
                                                    placeholder="Enter key"
                                                    disabled={
                                                        !overrideEnabled ||
                                                        !overriddenWorkflowParameters[index]?.isCustom
                                                    }
                                                    className={`input-field ${
                                                        keyError.length > 0 ? 'input-error' : ''
                                                    }`}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title={overriddenWorkflowParameters[index]?.value || ''}>
                                        <div className="form-item-wrapper">
                                            <Form.Item
                                                name={[name, 'value']}
                                                noStyle
                                                fieldKey={[fieldKey, 'value']}
                                                rules={[{ required: true, message: 'Value is required' }]}
                                                className="form-item"
                                            >
                                                <Input
                                                    placeholder="Enter value"
                                                    className={`input-field ${
                                                        valError.length > 0 ? 'input-error' : ''
                                                    }`}
                                                    disabled={!overrideEnabled}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Tooltip>
                                    <div className="icon-container">
                                        {overriddenWorkflowParameters[index]?.isCustom && (
                                            <i
                                                onClick={
                                                    overrideEnabled
                                                        ? () => {
                                                              remove(name);
                                                              handleRemoveField(index);
                                                          }
                                                        : undefined
                                                }
                                                className="alpha-icon md delete-icon"
                                            />
                                        )}
                                        {!overriddenWorkflowParameters[index]?.isCustom &&
                                            overriddenWorkflowParameters[index]?.description && (
                                                <Tooltip title={overriddenWorkflowParameters[index].description}>
                                                    <InfoCircleOutlined className="info-icon" />
                                                </Tooltip>
                                            )}
                                    </div>
                                </Space>
                                {rowErrors.length > 0 && <div className="error-message">{rowErrors.join(', ')}</div>}
                            </React.Fragment>
                        );
                    })}
                    <Button onClick={handleAddField} block disabled={!overrideEnabled}>
                        + Add Custom Parameter
                    </Button>
                </>
            )}
        </Form.List>
    );
};
