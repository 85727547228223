import * as React from 'react';
import { inject, observer } from 'mobx-react';
import {
    STORE_TEST_PROJECT,
    STORE_TEST_PROJECT_DASHBOARD,
    STORE_TEST_PROJECT_TOPIC_WIZARD,
    STORE_TEST_PROJECT_WIZARD
} from '../stores/constants';
import { TestProjectStores } from '../stores';
import { TestProjectDashboard } from '../components';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';

const TestProjectDashboardPage: React.FC<TestProjectStores> = ({
    projectTestProjectsUI,
    projectTestProjectsWizardUI,
    projectTestProjectsDashboardUI,
    projectTestProjectsTopicWizardUI
}) => {
    if (
        !projectTestProjectsUI ||
        !projectTestProjectsWizardUI ||
        !projectTestProjectsDashboardUI ||
        !projectTestProjectsTopicWizardUI
    ) {
        return null;
    }

    return (
        <HasPermission permissionClaim={AppPermissions.CanManageTestProjects}>
            <TestProjectDashboard
                store={projectTestProjectsDashboardUI}
                wizardStore={projectTestProjectsWizardUI}
                topicWizardStore={projectTestProjectsTopicWizardUI}
            />
        </HasPermission>
    );
};

export default inject(
    STORE_TEST_PROJECT,
    STORE_TEST_PROJECT_WIZARD,
    STORE_TEST_PROJECT_DASHBOARD,
    STORE_TEST_PROJECT_TOPIC_WIZARD
)(observer(TestProjectDashboardPage));
