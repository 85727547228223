import { action, observable } from 'mobx';
import { TestProjectTopic } from '../types';

export default class TestProjectTopicMappingModel {
    @observable
    newValue?: string;

    sourceTopic: TestProjectTopic;

    constructor(sourceTopic: TestProjectTopic) {
        this.sourceTopic = sourceTopic;
    }

    @action.bound
    setNewValue(newValue: string) {
        this.newValue = newValue;
    }

    @action.bound
    clearNewValue() {
        this.newValue = undefined;
    }
}
