import * as React from 'react';
import { inject, observer } from 'mobx-react';
import EditDataCrafterTemplate from '../components/EditDataCrafterTemplate';
import { DataCrafterTemplatesStores } from '../stores';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { STORE_DATA_CRAFTER_TEMPLATES, STORE_DATA_CRAFTER_TEMPLATE_FORM } from '../stores/constants';

const EditDataCrafterTemplateScreen: React.FC<DataCrafterTemplatesStores> = ({
    dataCrafterTemplates,
    dataCrafterTemplateForm
}) => {
    if (!dataCrafterTemplates || !dataCrafterTemplateForm) {
        return null;
    }

    return (
        <HasPermission permissionClaim={AppPermissions.CanManageDataCrafterTemplates}>
            <EditDataCrafterTemplate store={dataCrafterTemplates} formStore={dataCrafterTemplateForm} />
        </HasPermission>
    );
};

export default inject(
    STORE_DATA_CRAFTER_TEMPLATES,
    STORE_DATA_CRAFTER_TEMPLATE_FORM
)(observer(EditDataCrafterTemplateScreen));
