import { Utils } from '../../common/services/Utils';
import appClient, { ResultApi } from '../../common/services/AppClient';
import {
    TestProjectBaseline,
    TestProjectListModel,
    TestProjectRunResults,
    InputBindingMetadataBaseDto
} from '../types';
import security from '../../common/services/SecurityService';
import { BindingGroup } from '../../field_bindings/types';
import { TestProjectTopicsMapPayloadModel } from '../models';

// Babel can't handle type exporting, so have to fall back to this
type TestProjectInputType = 'Tags' | 'Bindings' | 'ProjectFields';

export default class TestProjectService {
    async getTestProjects(projectId: string): Promise<TestProjectListModel[]> {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects`;
        const resp = await appClient.get<TestProjectListModel[]>(url);
        return resp.unwrapOr(Array<TestProjectListModel>());
    }

    async getInputBindings(projectId: string): Promise<InputBindingMetadataBaseDto[]> {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/inputs-meta`;
        const resp = await appClient.get<InputBindingMetadataBaseDto[]>(url);
        return resp.unwrapOr([]);
    }

    async getProjectFieldGroups(projectId: string): Promise<BindingGroup[]> {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/project-fields-meta`;
        const resp = await appClient.get<BindingGroup[]>(url);
        return resp.unwrapOr([]);
    }

    async createTestProject(
        projectId: string,
        name: string,
        packageIds: string[],
        inputsType: TestProjectInputType,
        topicIds: string[],
        applicationId?: string,
        inputGroupId?: string,
        defaultBaselineFuzzy?: number
    ) {
        const request = {
            name,
            packageIds,
            inputsType,
            topicIds,
            applicationId,
            inputGroupId,
            defaultBaselineFuzzy
        };

        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects`;
        const resp = await appClient.post<unknown>(url, request);
        return resp;
    }

    async updateTestProject(
        projectId: string,
        testProjectId: string,
        name: string,
        packageIds: string[],
        inputsType: TestProjectInputType,
        topicIds: string[],
        applicationId?: string,
        inputGroupId?: string,
        defaultBaselineFuzzy?: number
    ) {
        const request = {
            name,
            packageIds,
            inputsType,
            topicIds,
            applicationId,
            inputGroupId,
            defaultBaselineFuzzy
        };

        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/${testProjectId}`;
        const resp = await appClient.update<unknown>(url, request);
        return resp;
    }

    async getTestProject(projectId: string, testProjectId: string): Promise<TestProjectListModel> {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/${testProjectId}`;
        const resp = await appClient.get<TestProjectListModel>(url);
        return resp.unwrapOr({} as TestProjectListModel);
    }

    async getTestProjectBaselines(projectId: string, testProjectId: string): Promise<TestProjectBaseline[]> {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/${testProjectId}/baselines`;
        const resp = await appClient.get<TestProjectBaseline[]>(url);
        return resp.unwrapOr([]);
    }

    async createTestProjectBaseline(
        projectId: string,
        testProjectId: string,
        packageId: string,
        topicId: string,
        value: string,
        fuzzy?: number
    ): Promise<ResultApi<TestProjectBaseline>> {
        const request = {
            packageId,
            topicId,
            value,
            fuzzy
        };

        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/${testProjectId}/baselines`;
        const resp = await appClient.post<TestProjectBaseline>(url, request);
        return resp;
    }

    async updateTestProjectBaseline(
        projectId: string,
        testProjectId: string,
        baselineId: string,
        value: string,
        fuzzy?: number
    ): Promise<ResultApi<unknown>> {
        const request = {
            value,
            fuzzy
        };

        const url =
            process.env.REACT_APP_MANAGE_URL +
            `projects/${projectId}/test-projects/${testProjectId}/baselines/${baselineId}`;
        const resp = await appClient.update(url, request);
        return resp;
    }

    async runTestProject(projectId: string, testProject: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/${testProject}/run`;
        const resp = await appClient.post(url);
        return resp;
    }

    async getTestProjectRunResults(projectId: string, testProject: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/${testProject}/runs`;
        const resp = await appClient.get<TestProjectRunResults[]>(url);
        return resp.unwrapOr([]);
    }

    async deleteTestProject(projectId: string, testProjectId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/${testProjectId}`;
        const resp = await appClient.delete(url);
        return resp;
    }

    async exportTestProjectBaselines(projectId: string, testProjectId: string) {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'testProjectBaselinesPostForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';

        await security.invoke(token => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url =
                process.env.REACT_APP_MANAGE_URL +
                `projects/${projectId}/test-projects/${testProjectId}/baselines/export${t}`;
            Utils.downloadFile(url, mapForm, 'testProjectBaselinesPostForm');
            return Promise.resolve();
        });
    }

    async exportTestProjectRunResuts(projectId: string, testProjectId: string, testRunId: string) {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'testProjectRunPostForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';

        await security.invoke(token => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url =
                process.env.REACT_APP_MANAGE_URL +
                `projects/${projectId}/test-projects/${testProjectId}/runs/${testRunId}/export${t}`;
            Utils.downloadFile(url, mapForm, 'testProjectRunPostForm');
            return Promise.resolve();
        });
    }

    async updateTestProjectPackages(projectId: string, testProjectId: string, packageIds: string[]) {
        const request = {
            packageIds
        };
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/${testProjectId}/packages`;
        const resp = await appClient.update(url, request);
        return resp;
    }

    async updateTestProjectTopics(
        projectId: string,
        testProjectId: string,
        topicIds: string[],
        inputsType: TestProjectInputType,
        applicationId?: string,
        inputGroupId?: string
    ) {
        const request = {
            topicIds,
            inputsType,
            applicationId,
            inputGroupId
        };
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/${testProjectId}/topics`;
        const resp = await appClient.update(url, request);
        return resp;
    }

    async getTestRunResultById(projectId: string, testProjectId: string, testRunId: string) {
        const url =
            process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/${testProjectId}/runs/${testRunId}`;
        const resp = await appClient.get<TestProjectRunResults>(url);
        return resp.unwrapOr({} as TestProjectRunResults);
    }

    async moveBaselinesToPackages(
        projectId: string,
        testProjectId: string,
        oldPackageId: string,
        newPackageIds: string[]
    ) {
        const request = {
            oldPackageId,
            newPackageIds
        };
        const url =
            process.env.REACT_APP_MANAGE_URL +
            `projects/${projectId}/test-projects/${testProjectId}/switch-baselines-package`;
        const resp = await appClient.post(url, request);
        return resp;
    }

    async createCopyOfTestProject(projectId: string, testProjectId: string) {
        const url =
            process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/${testProjectId}/create-copy`;
        const resp = await appClient.post<unknown>(url);
        return resp;
    }

    async abortTestProjectRun(projectId: string, testProject: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/${testProject}/abort`;
        const resp = await appClient.post(url);
        return resp;
    }

    async cleanTestProject(projectId: string, testProjectId: string) {
        const url =
            process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/test-projects/${testProjectId}/clean-testproject`;
        const resp = await appClient.post(url);
        return resp;
    }

    async updateBaselineFromTestRun(
        projectId: string,
        testProjectId: string,
        testRunId: string,
        baselineId: string,
        newBaselineValue: string
    ) {
        const request = {
            baselineId,
            newBaselineValue
        };

        const url =
            process.env.REACT_APP_MANAGE_URL +
            `projects/${projectId}/test-projects/${testProjectId}/run/${testRunId}/topics`;

        const resp = await appClient.update(url, request);
        return resp;
    }

    async updateBaselineFuzzyFromTestRun(
        projectId: string,
        testProjectId: string,
        testRunId: string,
        baselineId: string,
        newBaselineFuzzyValue: number
    ) {
        const request = {
            baselineId,
            newBaselineFuzzyValue
        };

        const url =
            process.env.REACT_APP_MANAGE_URL +
            `projects/${projectId}/test-projects/${testProjectId}/run/${testRunId}/topics/fuzzy`;

        const resp = await appClient.update(url, request);
        return resp;
    }

    async updateTestProjectTopicsType(
        projectId: string,
        testProjectId: string,
        topicMappings: TestProjectTopicsMapPayloadModel[],
        targetInputType: TestProjectInputType,
        targetApplicationId?: string,
        targetInputGroupId?: string
    ) {
        const request = {
            topicMappings,
            targetInputType,
            targetApplicationId,
            targetInputGroupId
        };

        const url =
            process.env.REACT_APP_MANAGE_URL +
            `projects/${projectId}/test-projects/${testProjectId}/update-topics-type`;
        const resp = await appClient.post(url, request);
        return resp;
    }
}
