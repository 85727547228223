import { Button, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { TestProjectTopicWizardStore } from '../../stores';
import TestProjectTopicsTypeSelector from './TestProjectTopicsTypeSelector';
import TestProjectTopicsAppSelector from './TestProjectTopicsAppSelector';
import TestProjectTopicsMappingPanel from './TestProjectTopicsMappingPanel';
import './TestProjectTopicsWizard.less';
import TestProjectTopicsAppInputGroupSelector from './TestProjectTopicsAppInputGroupSelector';

type Props = {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
    store: TestProjectTopicWizardStore;
};

const TestProjectTopicsWizardDialog: React.FC<Props> = ({ isVisible, setIsVisible, store }) => {
    const onCancel = () => {
        setIsVisible(false);
        store.resetWizard();
    };

    const onFinish = async () => {
        const filledMappings = store.topicsToMap.filter(t => t.newValue != null);
        const confirmMessage =
            'You have incomplete mappings. Their baselines will be permanently deleted. Are you sure you want to proceed?';

        if (store.currentTestProject?.topics.length === filledMappings.length || confirm(confirmMessage)) {
            const isSuccessful = await store.submitMapping();

            if (isSuccessful) {
                onCancel();
                store.initWizardData();
            }
        }
    };

    return (
        <Modal
            title="Change test project topics type"
            visible={isVisible}
            onCancel={onCancel}
            maskClosable={false}
            destroyOnClose
            centered
            width={850}
            footer={[
                <Button key="submit" onClick={onFinish} size="large" type="primary" disabled={!store.isValidMapping}>
                    Submit
                </Button>
            ]}
        >
            <div className="topics-wizard-type-selection-wrapper">
                <TestProjectTopicsTypeSelector store={store} />
                <TestProjectTopicsAppSelector store={store} />
                <TestProjectTopicsAppInputGroupSelector store={store} />
            </div>
            <div className="topic-wizard-mapping-panel-wrapper">
                <TestProjectTopicsMappingPanel store={store} />
            </div>
        </Modal>
    );
};

export default observer(TestProjectTopicsWizardDialog);
