import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { FlexContentContainer } from '../../common/components';
import { RulesStores } from '../stores';
import { STORE_TAG_PREVIEW } from '../constants';
import { TagPreview } from '../components';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

type Props = RulesStores;

export const TagPreviewPage: React.FC<Props> = ({ TagsPreviewUI }) => {
    const lines = !TagsPreviewUI!.isLoading ? <TagPreview store={TagsPreviewUI!} /> : <LoadingIndicator />;

    return <FlexContentContainer content={lines} />;
};

export default inject(STORE_TAG_PREVIEW)(observer(TagPreviewPage));
