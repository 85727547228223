import * as React from 'react';
import { TestProjectTopicWizardStore } from '../../stores';
import { Observer, observer } from 'mobx-react-lite';
import { Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TestProjectTopicMappingModel } from '../../models';
import { ArrowRightOutlined } from '@ant-design/icons';

type Props = {
    store: TestProjectTopicWizardStore;
};

const TestProjectTopicsMappingPanel: React.FC<Props> = ({ store }) => {
    if (!store.topicsToMap.length) {
        return null;
    }

    const columns: ColumnsType<TestProjectTopicMappingModel> = [
        {
            title: 'Source topic',
            key: 'sourceTopic',
            render: (text, record) => {
                return record.sourceTopic.name;
            }
        },
        {
            title: '',
            key: 'operation',
            render: () => <ArrowRightOutlined />
        },
        {
            title: 'Target topic',
            key: 'targetTopic',
            render: (_, record) => {
                return (
                    <Observer>
                        {() => (
                            <Select
                                style={{ width: '250px' }}
                                filterOption={(input, option) =>
                                    option?.title && option.title.toLowerCase().includes(input.toLowerCase())
                                }
                                options={store.mappingDataSource.map(src => {
                                    return {
                                        label: src.groupName,
                                        options: src.items.map(i => ({
                                            title: i.name,
                                            label: i.name,
                                            value: i.id
                                        }))
                                    };
                                })}
                                showSearch
                                value={record.newValue}
                                onChange={value => record.setNewValue(value)}
                                allowClear
                            />
                        )}
                    </Observer>
                );
            }
        }
    ];

    return (
        <div className="topics-mapping-panel">
            <Table
                dataSource={store.topicsToMap}
                columns={columns}
                pagination={false}
                rowKey={r => r.sourceTopic.topicId}
            />
        </div>
    );
};

export default observer(TestProjectTopicsMappingPanel);
