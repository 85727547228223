import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import {
    Row,
    Tree,
    Col,
    Input,
    Select,
    Button,
    Spin,
    Empty,
    Checkbox,
    Tag,
    Layout,
    Tooltip,
    Popover,
    List,
    Pagination
} from 'antd';
import { PreviewResult, TagPreviewResultWithConditionName } from '../../types';
import { SelectWhenScroll } from '../../../common/components/SelectWhenScroll';
import { CaretRightOutlined, CopyOutlined, EditOutlined } from '@ant-design/icons';
import LayoutHeader from '../../../../components/LayoutHeader';
import { Utils } from '../../../common/services/Utils';
import { HasPermission } from '../../../authorization/components/HasPermission';
import { AppPermissions } from '../../../authorization/Permissions';
import PreviewVisualStoreBase from '../../stores/PreviewVisualStoreBase';
import { TableBlockPreview, MatchedConditionLabel } from '..';
import ReactJson from 'react-json-view';
import './TagPreview.less';

const { TreeNode } = Tree;
const Option = Select.Option;
const { Content } = Layout;
type Props = {
    store: PreviewVisualStoreBase;
    isAplicatonBindingPreview?: boolean;
};

const isTagPreviewResultWithConditionName = (
    result: Record<string, unknown>
): result is TagPreviewResultWithConditionName =>
    !!('conditionName' in result && typeof result.conditionName === 'string');

const TagPreview: React.FC<Props> = ({ store, isAplicatonBindingPreview }) => {
    React.useEffect(() => {
        if (store.project) {
            store.loadProject(store.project);
            store.loadProjectPackages();
        } else {
            const projectId = window.location.pathname.split('/')[2];
            store.initUsingProjectId(projectId);
        }
    }, [store.project, store]);

    React.useEffect(() => {
        store.resetPreview();
    }, [store.selectedItemLabel, store]);

    React.useEffect(() => {
        store.subscribeToPackageChanges();
        return () => store.unsubscribeFromPackageChanges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderPackageNodes = (packageId: string, previewResults: PreviewResult[], dataId: string) => {
        if (
            previewResults == null ||
            !previewResults.length ||
            previewResults.every(p => !p.entries || !p.entries.length)
        ) {
            return <></>;
        }
        var resultIndexToHighlight = previewResults.findIndex(x => x.entries && x.entries.length);
        store.addExpandedKey(packageId, `${dataId}-${resultIndexToHighlight}`);
        return (
            <>
                {previewResults.map((p, i) => (
                    <TreeNode
                        data-id-type="priority"
                        data-id-name={`${p.rule.name} Priority ${p.rule.priority}`}
                        key={`${dataId}-${i}`}
                        title={
                            <div
                                style={
                                    !isAplicatonBindingPreview && i === resultIndexToHighlight
                                        ? { backgroundColor: '#c0e7c7fb' }
                                        : {}
                                }
                            >
                                {p.rule.name}{' '}
                                {p.rule.priority !== undefined ? (
                                    <span style={{ opacity: 0.5 }}>&nbsp;&nbsp;Priority&nbsp;{p.rule.priority}</span>
                                ) : null}
                                <HasPermission
                                    entityId={store?.currentProject?.id}
                                    permissionClaim={AppPermissions.CanEditImportExportRules}
                                >
                                    <Tooltip title="Go to rule">
                                        <Button
                                            style={store.isEditable ? { display: 'inline' } : { display: 'none' }}
                                            icon={<EditOutlined style={{ fontSize: 12, verticalAlign: 'baseline' }} />}
                                            type="link"
                                            onClick={() => store.editRule(p.rule.id)}
                                        />
                                    </Tooltip>
                                </HasPermission>
                            </div>
                        }
                    >
                        {p.entries &&
                            p.entries.map((e, index) => {
                                const type =
                                    e.resultTokens.length > 0
                                        ? e.resultTokens[e.resultTokens.length - 1].token.type
                                        : 'Text';

                                return (
                                    <TreeNode
                                        key={`${dataId}-${i}-${index}`}
                                        data-id-type="text-result"
                                        data-id-name={e.tag.values.join(' ')}
                                        title={e.tag.values.map(t => {
                                            let tagValJsonObject: JSX.Element | undefined = undefined;

                                            try {
                                                tagValJsonObject =
                                                    type === 'Table' ? (
                                                        <TableBlockPreview content={t} />
                                                    ) : (
                                                        <ReactJson src={JSON.parse(t)} displayDataTypes={false} />
                                                    );
                                            } catch {
                                                // do nothing
                                            }

                                            return (
                                                <>
                                                    <Tooltip title="Copy value to clipboard">
                                                        <Button
                                                            icon={
                                                                <CopyOutlined
                                                                    style={{ fontSize: 12, verticalAlign: 'baseline' }}
                                                                />
                                                            }
                                                            type="link"
                                                            onClick={() => Utils.copyValToClipboard(t)}
                                                        />
                                                    </Tooltip>
                                                    <Popover
                                                        key={`${dataId}-${i}-${index}-${t}`}
                                                        content={
                                                            <div
                                                                style={{
                                                                    maxWidth: 500,
                                                                    maxHeight: 400,
                                                                    overflow: 'auto'
                                                                }}
                                                            >
                                                                {tagValJsonObject != null ? tagValJsonObject : t}
                                                            </div>
                                                        }
                                                        autoAdjustOverflow
                                                        placement="bottomLeft"
                                                    >
                                                        <Tag>{t}</Tag>
                                                    </Popover>
                                                </>
                                            );
                                        })}
                                    />
                                );
                            })}
                    </TreeNode>
                ))}
            </>
        );
    };

    const renderNodes = () => {
        return store.previewResults.map(p => {
            if (store.currentCollapsedNodeKey !== p.packageId) {
                store.addExpandedKey(p.packageId, `${p.packageId}-root`);
            }
            return (
                <Observer key={p.packageId}>
                    {() => (
                        <Tree
                            data-id={`tag-preview-result-${p.packageId}`}
                            showLine
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onExpand={(n: string[], event: any) => store.setEntriesExpandedKeys(p.packageId, n, event)}
                            expandedKeys={store.entriesExpandedKeys[p.packageId]}
                        >
                            <TreeNode
                                key={`${p.packageId}-root`}
                                data-id-type="document-name"
                                data-id-name={store.getPackageNameById(p.packageId)}
                                expanded
                                title={
                                    <div style={{ display: 'flex', gap: 10 }}>
                                        <span
                                            title={store.getPackageNameById(p.packageId)}
                                            style={{
                                                maxWidth: 400,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: 'inline-block'
                                            }}
                                        >
                                            {store.getPackageNameById(p.packageId)}
                                        </span>

                                        {isTagPreviewResultWithConditionName(p) && (
                                            <MatchedConditionLabel conditionName={p.conditionName} />
                                        )}
                                    </div>
                                }
                            >
                                {renderPackageNodes(
                                    p.packageId,
                                    p.previewResults,
                                    `tag-preview-result-${p.packageId}-node`
                                )}
                            </TreeNode>
                        </Tree>
                    )}
                </Observer>
            );
        });
    };

    const getResult = () => {
        if (!store.previewResults) {
            return <Empty style={{ marginTop: 50 }} />;
        }

        return <>{renderNodes()}</>;
    };

    const handlePageChange = (page: number) => {
        store.setPackagesListPage(page);
        store.loadProjectPackages();
    };

    return (
        <Layout className="screen-size" style={{ ...{ height: '100%', background: 'white', overflowY: 'hidden' } }}>
            <LayoutHeader
                subtitle={Utils.getSubtitle(store!.currentProject)}
                title={store.selectedItemLabel!}
                helpMessage="  "
                buttons={[
                    <Button
                        key="user-manager-add-user-button"
                        data-id="tag-preview-back-button"
                        size="large"
                        className="light"
                        onClick={store.goToInitialList}
                    >
                        Go back
                    </Button>
                ]}
            />
            <Layout>
                <Content>
                    <Row style={{ height: '100%' }}>
                        <Col className="tag-preview-package-container" span={8} style={{ height: '100%' }}>
                            <div style={{ height: 'calc(100% - 81px)', overflow: 'auto' }}>
                                <Input.Search
                                    data-id="rule-preview-package-filter-input"
                                    style={{ margin: 10, width: '90%' }}
                                    className="alpha-search-input"
                                    allowClear
                                    placeholder="Filter results..."
                                    value={store.docNameInputValue || ''}
                                    onChange={v => {
                                        store.setDocNameInputValue(v.currentTarget.value);
                                        store.debouncedSearchDocuments(v.currentTarget.value);
                                    }}
                                />
                                <SelectWhenScroll
                                    data-id="rule-preview-tag-select"
                                    style={{ margin: '0px 10px', width: '90%' }}
                                    mode="tags"
                                    placeholder="Tags"
                                    value={store.currentTags}
                                    onChange={tags => {
                                        store.setTags(tags);
                                        store.loadProjectPackages();
                                    }}
                                >
                                    {store.tags.map((k, i) => (
                                        <Option key={`kw-${i}`} value={k}>
                                            {k}
                                        </Option>
                                    ))}
                                </SelectWhenScroll>
                                <Pagination
                                    pageSize={store.pageSize}
                                    current={store.packagesListPage}
                                    onChange={handlePageChange}
                                    showSizeChanger={false}
                                    total={store.totalPackagesCount}
                                    className="tag-preview-project-package-filter-pagination"
                                    size="small"
                                />
                                <Checkbox
                                    style={{ marginBottom: 20, paddingTop: 20, marginLeft: 11 }}
                                    indeterminate={store.selectAllIndeterminate}
                                    checked={store.checkAllKeys}
                                    onChange={e => {
                                        store.setCheckAllKeys(e.target.checked);
                                    }}
                                    disabled={
                                        !store.filteredDocumentsForCurrentProject ||
                                        !store.filteredDocumentsForCurrentProject ||
                                        !store.filteredDocumentsForCurrentProject.length
                                    }
                                >
                                    <span style={{ marginLeft: 4 }}>Select all</span>
                                </Checkbox>

                                <Observer>
                                    {() => (
                                        <List
                                            data-id="rule-preview-package-list"
                                            loading={store.isLoadingPackages}
                                            size="small"
                                            bordered={false}
                                            dataSource={store.readyPackages}
                                            style={{ margin: '0px 10px', width: '90%' }}
                                            renderItem={item => (
                                                <List.Item
                                                    className="rule-preview-packages-tree-node"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        padding: '4px 0px',
                                                        border: 'none',
                                                        justifyContent: 'start'
                                                    }}
                                                    key={item.id}
                                                    data-id-type="document-name"
                                                    data-id-name={item.name}
                                                >
                                                    <Observer>
                                                        {() => (
                                                            <>
                                                                <Checkbox
                                                                    checked={store.selectedPackageIds.includes(item.id)}
                                                                    style={{ marginRight: 12 }}
                                                                    onChange={e => {
                                                                        store.handlePackageCheck(
                                                                            item.id,
                                                                            e.target.checked
                                                                        );
                                                                    }}
                                                                />
                                                                <span style={{ whiteSpace: 'pre' }}>{item.name}</span>
                                                            </>
                                                        )}
                                                    </Observer>
                                                </List.Item>
                                            )}
                                        />
                                    )}
                                </Observer>
                            </div>
                            <div className="tag-preview-run-button-container">
                                <Button
                                    data-id="tag-preview-run-button"
                                    type="primary"
                                    size="large"
                                    icon={<CaretRightOutlined />}
                                    disabled={!store!.selectedPackageIds.length || store!.isExecuting}
                                    onClick={() => store!.loadPreviewResults()}
                                >
                                    Run test
                                </Button>
                            </div>
                        </Col>
                        <Col data-id="tag-preview-results-container" span={16} style={{ height: '100%' }}>
                            {store!.isExecuting ? (
                                <Spin style={{ marginLeft: '50%', marginTop: '30%' }} tip="Loading..." />
                            ) : (
                                <div style={{ width: '100%', overflow: 'auto', height: 'calc(100% - 32px)' }}>
                                    {getResult()}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(TagPreview);
