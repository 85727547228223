import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { TestProjectDashboardStore } from '../stores';
import TimeRemaining from './TimeRemaining';
import TimeElapsed from './TimeElapsed';
import TestProjectProgressBar from './TestProjectProgressBar';

type TestProjectProgressSectionProps = {
    store: TestProjectDashboardStore;
};

const TestProjectProgressSection: React.FC<TestProjectProgressSectionProps> = ({ store }) => {
    const msRemaining =
        store.testProject?.processedPackageCount && store.testProject.avgTopicProcessingTime
            ? (store.testProject.packageIds.length - store.testProject.processedPackageCount) *
              store.testProject.avgTopicProcessingTime
            : 0;

    return (
        <div className="test-project-dashboard-progress-section">
            {store.testProject?.lastRunTime && !store.testProject.isAborting && (
                <TimeElapsed datetime={store.testProject?.lastRunTime} />
            )}
            {store.testProject?.isAborting && <p className="bold-progress-count">Aborting...</p>}
            <TestProjectProgressBar
                executedCount={store.testProject?.processedPackageCount}
                totalCount={store.testProject?.packageIds?.length}
            />

            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10, marginBottom: 10 }}>
                <p className="bold-progress-count">{store.testProject?.processedPackageCount}&nbsp;</p>out of
                <p className="bold-progress-count">
                    &nbsp;{store.testProject?.packageIds.length}&nbsp;
                </p> packages processed
            </div>
            <TimeRemaining milliseconds={msRemaining} />
        </div>
    );
};

export default observer(TestProjectProgressSection);
