import * as React from 'react';
import './styles/common.less';
import './styles/rules.less';
import './styles/ref-data.less';
import './styles/index.css';
import './styles/side-menu.less';
import './styles/iota.less';
import './styles/uploaded-packages.less';
import './styles/administration.less';
import './styles/labels.less';
import './styles/nestedTable.css';
import './styles/project-field-bindings.less';
import './styles/form-types.less';
import './styles/project-setting.less';
import './styles/test-projects.less';
import 'react-sortable-tree-patch-react-17/style.css';
import './tracing';
import { Provider } from 'mobx-react';
import { Router } from 'react-router';

import AdminApp from './containers/App';

import { injectRootStores } from './modules/common/stores';
import { injectIntLabelsStores } from './modules/interactive_labels/stores';
import { injectIotaAppStores } from './modules/iota_applications/stores';
import { injectIotaConnStores } from './modules/iota_connections/stores';
import { injectIotaSessStores } from './modules/iota_sessions/stores';
import { injectListLabelsStores } from './modules/list_labels/stores';
import { injectMlStorageStores } from './modules/ml_storage/stores';
import { injectProjectStores } from './modules/project_management/stores';
import { injectUploadStores } from './modules/upload_data/stores';
import { injectViewerStores } from './modules/viewer_base/stores';
import { injectRulesStores } from './modules/rules/stores';
import { injectAdministrationStores } from './modules/administration/stores';
import { injectReferenceDataStores } from './modules/iota_reference_data/stores';
import { injectUserProfileStores } from './modules/user_profile/stores';
import { injectIotaAuditStores } from './modules/iota_audit/stores';
import { injectFieldBindingsStores } from './modules/field_bindings/stores';
import { injectFormTypesStores } from './modules/form_types/stores';
import { injectTestProjectStores } from './modules/test_projects/stores';
import { injectHistoryStores } from './modules/history/stores';
import { injectInstructWorkflowStores } from './modules/instruct_workflows/stores';
import { injectSchemaGeneratorStores } from './modules/schema_generator/stores';
import { injectApplicationDefinitionConditionalStores } from './modules/application_definition_conditional/stores';
import { injectDataCrafterTemplatesStores } from './modules/data_crafter_templates/stores';
// configure({ enforceActions: true });

function init() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rootStores: any = {};

    const { history } = injectRootStores(rootStores);
    injectIntLabelsStores(rootStores);
    injectProjectStores(rootStores);
    injectUploadStores(rootStores);
    injectViewerStores(rootStores);
    injectRulesStores(rootStores);
    injectIotaAppStores(rootStores);
    injectIotaConnStores(rootStores);
    injectIotaSessStores(rootStores);
    injectListLabelsStores(rootStores);
    injectMlStorageStores(rootStores);
    injectAdministrationStores(rootStores);
    injectReferenceDataStores(rootStores);
    injectUserProfileStores(rootStores);
    injectIotaAuditStores(rootStores);
    injectFieldBindingsStores(rootStores);
    injectFormTypesStores(rootStores);
    injectTestProjectStores(rootStores);
    injectHistoryStores(rootStores);
    injectInstructWorkflowStores(rootStores);
    injectSchemaGeneratorStores(rootStores);
    injectApplicationDefinitionConditionalStores(rootStores);
    injectDataCrafterTemplatesStores(rootStores);

    // TODO: find a better way to to keep store's state when doing hot reload
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).stores = (window as any).stores || rootStores;
    }

    return { rootStores, history };
}

class App extends React.Component {
    render() {
        console.log(`Version: ${process.env.REACT_APP_VERSION}`);
        const { rootStores, history } = init();
        return (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            <Provider {...((window as any).stores || rootStores)}>
                {/* ConnectedRouter will use the store from Provider automatically */}
                <Router history={history}>
                    <AdminApp />
                </Router>
            </Provider>
        );
    }
}

export default App;
