export default class TopicMappingTargetItemGroupModel {
    groupName: string;
    items: TopicMappingTargetItemModel[];

    constructor(groupName: string, items: TopicMappingTargetItemModel[]) {
        this.groupName = groupName;
        this.items = items;
    }
}

class TopicMappingTargetItemModel {
    id: string;
    name: string;
}
