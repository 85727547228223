/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action, reaction, runInAction, computed } from 'mobx';
import RouterStore from './RouterStore';
import ErrorStore from './ErrorStore';
import Project from '../models/Project';
import ProjectsStore from './ProjectsStore';
import { PackageLinesResponse, PackagesRequest, PackagesResponse, SmartIndexSettings } from '../types';
import { Package, PackageState, ProjectKeywordRenameModel } from '../models';
import { ProjectPagesNavigation } from '../../project_management/routes';
import { ListLabelsPagesNavigation } from '../../list_labels/routes';
import { MlStoragePagesNavigation } from '../../ml_storage/routes';
import { InteractivePagesNavigation } from '../../interactive_labels/routes';
import { RulesPagesNavigation } from '../../rules/routes';
import { IotaConnPagesNavigation } from '../../iota_connections/routes';
import { IotaAppPagesNavigation } from '../../iota_applications/routes';
import { SessionsPagesNavigation } from '../../iota_sessions/routes';
import { ReferenceDataNavigation } from '../../iota_reference_data/routes';
import { IotaAuditPagesNavigation } from '../../iota_audit/routes';
import { FieldBindingsPageNavigation } from '../../field_bindings/routes';
import { FormTypesPageNavigation } from '../../form_types/routes';
import { ProjectSettingsNavigation } from '../../project_settings/routes';
import { TestProjectsNavigation } from '../../test_projects/routes';
import { HistoryNavigation } from '../../history/routes';
import { InstructWorkflowsNavigation } from '../../instruct_workflows/routes';
import { SchemaGeneratorNavigation } from '../../schema_generator/routes';
import { PAGE_SIZE } from './Pager';
import { ProjectsService } from '../services';
import { AppPermissions } from '../../authorization/Permissions';
import security from '../../common/services/SecurityService';
import { DataCrafterTemplatesNavigation } from '../../data_crafter_templates/routes';

class ProjectsRootVisualStore {
    @observable
    currentProject: Project | null;

    @observable
    projectEditDialogVisible: boolean = false;

    @observable
    projectDeleteDialogVisible: boolean = false;

    @observable
    tags: string[] = [];

    @observable
    isLoading: boolean = false;

    @observable
    currentUserId: string;

    @computed
    get isPushServiceConnected() {
        return this.projectsStore.isPushServiceConnected;
    }

    @computed
    get currentProjectColor() {
        return this.currentProject ? this.currentProject.color : 'default';
    }

    @computed
    get projects() {
        return this.projectsStore.projects;
    }

    @computed
    get currentUserPermissions() {
        return this.projectsStore.currentUserPermissions;
    }

    @computed
    get hasAccessToAllEntities() {
        return this.currentUserPermissions.includes(AppPermissions.CanAccessAllEntities);
    }

    constructor(
        private router: RouterStore,
        public projectsStore: ProjectsStore,
        public errorStore: ErrorStore,
        private projectsService: ProjectsService
    ) {
        reaction(
            () => router.location,
            l => {
                if (l!.pathname === ProjectPagesNavigation.ProjectsPage) {
                    this.currentProject = null;
                }
            }
        );

        if (!this.currentProject) {
            reaction(
                () => projectsStore.projects,
                async (projects, r) => {
                    let storedId = sessionStorage.getItem('currentProject');
                    if (storedId) {
                        await this.setUpProjectById(storedId);
                    } else {
                        // Try to get project id from URL in case if it was opened in other tab or machine and session storage is not present there
                        let projectId = router.location?.pathname.match('(?<=projects/)(.*?)(?=/)');
                        if (projectId != null && projectId.length) {
                            await this.setUpProjectById(projectId![0]);
                        }
                    }

                    r.dispose();
                }
            );
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        security.inst.loadUserInfo().then((r: any) => {
            this.currentUserId = r.sub;
        });
    }

    @action.bound
    setProjectEditDialogVisible(visible: boolean) {
        this.projectEditDialogVisible = visible;
    }

    @action.bound
    updateProjectSmartIndexSettings(smartIndexSettings: SmartIndexSettings | null) {
        if (this.currentProject) {
            this.currentProject.smartIndexSettings = smartIndexSettings;
        }
    }

    @action.bound
    setProjectDeleteDialogVisible(visible: boolean) {
        this.projectDeleteDialogVisible = visible;
    }

    @action.bound
    setCurrentProject(currentProject: Project | null) {
        this.currentProject = currentProject;
    }

    @action.bound
    async updateCurrentProject(
        name: string,
        keywords: string[],
        color: string,
        featureFlags: string | null,
        smartIndexSettings: string | null
    ) {
        if (this.currentProject) {
            this.isLoading = true;

            await this.projectsStore.updateProject(
                this.currentProject.id,
                name,
                keywords,
                color,
                featureFlags,
                smartIndexSettings
            );

            runInAction(() => {
                if (this.currentProject) {
                    this.currentProject =
                        this.projectsStore.projects.find(p => p.id === this.currentProject!.id) || null;
                }

                this.isLoading = false;
            });
        }
    }

    @action.bound
    async deleteProject(projectId: string) {
        this.projectsStore.deleteProject(projectId);
    }

    // TODO: Removed references to other moddules.
    @action
    navigateToProjectsPage() {
        this.currentProject = null;
        this.router.pushToHistory(ProjectPagesNavigation.ProjectsPage);
    }

    @action
    async navigateToLabelsPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(ListLabelsPagesNavigation.ProjectLabelsPage.replace(':id', project.id));
    }

    @action
    async navigateToInteractiveLabelsPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(
            InteractivePagesNavigation.ProjectInteractiveLabelPage.replace(':id', project.id)
                .replace(':packageId', '0')
                .replace(':pageNumber', '0')
        );
    }

    @action
    async navigateToMetadataPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(ProjectPagesNavigation.ProjectMetadataPage.replace(':id', project.id));
    }

    @action
    async navigateToUploadedPackagesPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(ProjectPagesNavigation.ProjectMetadataPackagesPage.replace(':id', project.id));
    }

    @action
    async navigateToProjectAdministrationPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(ProjectPagesNavigation.ProjectAdministrationPage.replace(':id', project.id));
    }

    @action
    async navigateToRulesPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(RulesPagesNavigation.RulesListPage.replace(':projectId', project.id));
    }

    @action
    async navigateToMlStoragePage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(MlStoragePagesNavigation.MLStoragePage.replace(':id', project.id));
    }

    @action
    async navigateToApplicationDefinitionsPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(IotaAppPagesNavigation.ProjectApplicationDefinitionsPage.replace(':id', project.id));
    }

    @action
    async navigateToConnectionsDefinitionsPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(IotaConnPagesNavigation.ProjectConnectionsDefinitionsPage.replace(':id', project.id));
    }

    @action
    async navigateToEditApplicationDefinitionPage(project: Project, id: string) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(
            IotaAppPagesNavigation.ApplicationDefinitionEditPage.replace(':id', project.id).replace(':appId', id)
        );
    }

    @action
    async navigateToEditConnectionsDefinitionPage(project: Project, id: string) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(
            IotaConnPagesNavigation.ConnectionsDefinitionEditPage.replace(':id', project.id).replace(':appId', id)
        );
    }

    async navigateToApplicationDefinitionsSettingsPage(project: Project, appId: string) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(
            IotaAppPagesNavigation.ProjectApplicationDefinitionsSettings.replace(':id', project.id).replace(
                ':appId',
                appId
            )
        );
    }

    async navigateToConnectionsDefinitionsSettingsPage(project: Project, appId: string) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(
            IotaConnPagesNavigation.ProjectConnectionsDefinitionsSettings.replace(':id', project.id).replace(
                ':appId',
                appId
            )
        );
    }

    @action
    async navigateToSessionsPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(SessionsPagesNavigation.SessionsPage.replace(':id', project.id));
    }

    @action
    async navigateToTagsPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(RulesPagesNavigation.RulesListPage.replace(':projectId', project.id));
    }

    @action
    async navigateToConnectionsPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(RulesPagesNavigation.ConnectionsListPage.replace(':projectId', project.id));
    }

    @action
    async navigateToPackageProblemReportsPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(ProjectPagesNavigation.PackageProblemReportsPage.replace(':projectId', project.id));
    }

    @action
    async navigateToReferenceDataPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(ReferenceDataNavigation.ReferenceDataPage.replace(':projectId', project.id));
    }

    async navigateToEditIotaApplicationPage(project: Project, appId: string) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(
            IotaAppPagesNavigation.EditIotaApplicationPage.replace(':id', project.id).replace(':appId', appId)
        );
    }

    @action
    async navigateToIotaAuditPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(IotaAuditPagesNavigation.IotaAuditPage.replace(':projectId', project.id));
    }

    @action
    async navigateToFieldBindingsPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(
            FieldBindingsPageNavigation.ProjectFieldBindingsPage.replace(':projectId', project.id)
        );
    }

    @action
    async navigateToFormTypesPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(FormTypesPageNavigation.FormTypesPage.replace(':projectId', project.id));
    }

    @action
    async navigateToProjectSettingsPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(ProjectSettingsNavigation.ProjectSettingsPage.replace(':projectId', project.id));
    }

    @action
    async navigateToHistoryPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(HistoryNavigation.HistoryPage.replace(':projectId', project.id));
    }

    @action
    async navigateToInstructWorkflowsPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(InstructWorkflowsNavigation.InstructWorkflowsPage.replace(':projectId', project.id));
    }

    @action
    async navigateToSchemaGeneratorPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(SchemaGeneratorNavigation.SchemaGeneratorPage.replace(':projectId', project.id));
    }

    @action
    async navigateToDataCrafterTemplatesPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(
            DataCrafterTemplatesNavigation.DataCrafterTemplatesScreen.replace(':projectId', project.id)
        );
    }

    @action
    commitProject(project: Project) {
        return this.projectsStore.commitProject(project);
    }

    @action.bound
    async navigateToTestProjectPage(project: Project) {
        await this.setupCurrentProject(project);
        this.router.pushToHistory(TestProjectsNavigation.TestProjectsPage.replace(':projectId', project.id));
    }

    @action
    updateProjectMetadata(project: Project, renameList: ProjectKeywordRenameModel[]) {
        this.projectsStore.updateProjectMetadata(project, renameList);
    }

    async searchInPackages(request: PackagesRequest, pkg?: Package | undefined): Promise<PackageLinesResponse> {
        return this.projectsStore.searchInPackages(this.currentProject!, request, pkg);
    }

    @action
    async fetchTags() {
        const result = await this.projectsStore.fetchTags(this.currentProject!.id);
        runInAction(() => {
            this.tags = result;
        });
    }

    @action
    async fetchAllTags() {
        const result = await this.projectsStore.fetchTags();
        runInAction(() => {
            this.tags = result;
        });
    }

    @action.bound
    async reloadCurrentProject() {
        if (this.currentProject) {
            runInAction(() => {
                this.currentProject!.isPackagesLoaded = false;
            });
            const pkgs = this.currentProject.packages.filter(p =>
                [PackageState.ChangedAnchors, PackageState.ChangedLabels, PackageState.ChangedManualText].includes(
                    p.state
                )
            );
            await this.projectsStore.getPackages(this.currentProject);
            pkgs.forEach(x => {
                const index = this.currentProject!.packages.findIndex(p => p.id === x.id);
                if (index >= 0) {
                    this.currentProject!.packages[index].lines = x.lines;
                }
            });
        }
    }

    @action.bound
    addPackageInCurrentProject(pkg: Package) {
        try {
            if (this.currentProject) {
                let newPackages = this.currentProject.packages.slice();
                newPackages.push(pkg);
                runInAction(() => {
                    this.currentProject!.packages = newPackages;
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    @action.bound
    removePackageInCurrentProject(pkgId: string) {
        try {
            if (this.currentProject) {
                let newPackages = this.currentProject.packages.filter(p => p.id !== pkgId);
                runInAction(() => {
                    this.currentProject!.packages = newPackages;
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    @action.bound
    updatePackageStateInCurrentProject(index: number, state: PackageState) {
        try {
            if (this.currentProject) {
                let newPackages = this.currentProject.packages.slice();
                if (!newPackages[index]) {
                    return;
                }
                newPackages[index].serverState = state;
                runInAction(() => {
                    this.currentProject!.packages = newPackages;
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    @action.bound
    setCurrentProjectPackages(packages: Package[]) {
        try {
            if (this.currentProject) {
                runInAction(() => {
                    this.currentProject!.packages = packages;
                });
            }
        } catch (ex) {
            console.log(ex);
        }
    }

    @action.bound
    async searchInProject(
        term: string | null,
        page: number,
        tags: string[],
        allSources: boolean = false,
        pageSize: number = PAGE_SIZE,
        packageIds?: string[],
        states?: string[]
    ): Promise<PackagesResponse> {
        if (!this.currentProject) {
            return Promise.resolve({ lines: [], total: 0 });
        }

        const request = {
            projectId: this.currentProject.id,
            search: term,
            page: page,
            pageSize: pageSize,
            tags: tags,
            allSources: allSources,
            uploadedBy: this.hasAccessToAllEntities ? null : this.currentUserId,
            packageIds,
            states
        };

        return await this.projectsService.searchPackages(this.currentProject!, request);
    }

    async setupCurrentProject(project: Project) {
        if (this.currentProject === project) {
            return;
        }
        sessionStorage.setItem('currentProject', project.id);

        await this.projectsStore.getPackages(project);
        runInAction(() => {
            this.currentProject = project;
            this.fetchTags();
        });
    }

    private async setUpProjectById(projectId: string) {
        const currProject = this.projectsStore.projects.find(p => p.id === projectId);
        if (currProject) {
            await this.setupCurrentProject(currProject);
        }
    }
}

export default ProjectsRootVisualStore;
