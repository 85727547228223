import * as React from 'react';
import DataCrafterTemplatesListScreen from '../screens/OutputFileTemplatesListScreen';
import CreateDataCrafterTemplateScreen from '../screens/CreateOutputFileTemplateScreen';
import EditDataCrafterTemplateScreen from '../screens/EditDataCrafterTemplateScreen';
import { monitorRouterMatch, ProtectedRoute } from '../../common/components';
import { STORE_DATA_CRAFTER_TEMPLATES } from '../stores/constants';

export const DataCrafterTemplatesNavigation = {
    DataCrafterTemplatesScreen: '/projects/:projectId/data-crafter-templates',
    CreateDataCrafterTemplateScreen: '/projects/:projectId/data-crafter-templates/create',
    EditDataCrafterTemplateScreen: '/projects/:projectId/data-crafter-templates/:templateId/edit'
};

export default () => {
    return (
        <>
            <ProtectedRoute
                component={DataCrafterTemplatesListScreen}
                path={DataCrafterTemplatesNavigation.DataCrafterTemplatesScreen}
                exact
            />
            <ProtectedRoute
                component={CreateDataCrafterTemplateScreen}
                path={DataCrafterTemplatesNavigation.CreateDataCrafterTemplateScreen}
            />
            <ProtectedRoute
                component={monitorRouterMatch(STORE_DATA_CRAFTER_TEMPLATES, (s, m) =>
                    s[STORE_DATA_CRAFTER_TEMPLATES].matchCurrentTemplateData(m!.params)
                )(EditDataCrafterTemplateScreen)}
                path={DataCrafterTemplatesNavigation.EditDataCrafterTemplateScreen}
            />
        </>
    );
};
