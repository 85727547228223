import * as React from 'react';
import { TestProjectTopicWizardStore } from '../../stores';
import { observer } from 'mobx-react-lite';
import { Radio } from 'antd';

type Props = {
    store: TestProjectTopicWizardStore;
};

const TestProjectTopicsTypeSelector: React.FC<Props> = ({ store }) => {
    return (
        <Radio.Group onChange={e => store.setTargetInputType(e.target.value)} value={store.targetInputType}>
            {store.availableTargetInputTypes.map(inputType => (
                <Radio key={inputType} value={inputType}>
                    {store.getFriendlyTopicTypeName(inputType)}
                </Radio>
            ))}
        </Radio.Group>
    );
};

export default observer(TestProjectTopicsTypeSelector);
