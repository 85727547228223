import { STORE_DATA_CRAFTER_TEMPLATES, STORE_DATA_CRAFTER_TEMPLATE_FORM } from './constants';
import { RootStores } from '../../common/stores';
import DataCrafterTemplatesVisualStore from './DataCrafterTemplatesVisualStore';
import DataCrafterTemplateFormStore from './DataCrafterTemplateFormStore';
import { DataCrafterTemplatesService } from '../services';
import { STORE_PROJECTS_ROOT_UI, STORE_ROUTER, STORE_USERS } from '../../common/constants';
import { ProjectsService } from '../../common/services';

export { default as DataCrafterTemplatesVisualStore } from './DataCrafterTemplatesVisualStore';
export { default as DataCrafterTemplateFormStore } from './DataCrafterTemplateFormStore';

export function injectDataCrafterTemplatesStores(obj: RootStores) {
    const dataCrafterTemplatesService = new DataCrafterTemplatesService();
    const projectsService = new ProjectsService();

    const visualStore = new DataCrafterTemplatesVisualStore(
        obj[STORE_PROJECTS_ROOT_UI],
        dataCrafterTemplatesService,
        obj[STORE_ROUTER],
        projectsService,
        obj[STORE_USERS]
    );

    obj[STORE_DATA_CRAFTER_TEMPLATES] = visualStore;
    obj[STORE_DATA_CRAFTER_TEMPLATE_FORM] = new DataCrafterTemplateFormStore(visualStore, dataCrafterTemplatesService);
}

export type DataCrafterTemplatesStores = {
    [STORE_DATA_CRAFTER_TEMPLATES]?: DataCrafterTemplatesVisualStore;
    [STORE_DATA_CRAFTER_TEMPLATE_FORM]?: DataCrafterTemplateFormStore;
};
