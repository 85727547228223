import * as React from 'react';
import { TestProjectTopicWizardStore } from '../../stores';
import { observer } from 'mobx-react-lite';
import { Select } from 'antd';

type Props = {
    store: TestProjectTopicWizardStore;
};

const TestProjectAppInputGroupSelector: React.FC<Props> = ({ store }) => {
    if (
        store.currentApplicationMetadata?.applicationType !== 'ApplicationDefinitionConditional' ||
        store.currentApplicationInputGroups.length === 0
    ) {
        return null;
    }

    return (
        <Select
            style={{ width: '235px' }}
            options={store.currentApplicationInputGroups.map(ig => ({ title: ig.name, label: ig.name, value: ig.id }))}
            showSearch
            value={store.targetIotaAppInputGroupId}
            onChange={value => store.setTargetIotaAppInputGroupId(value)}
            filterOption={(input, option) => option?.title && option.title.toLowerCase().includes(input.toLowerCase())}
            placeholder="Select target input group"
        />
    );
};

export default observer(TestProjectAppInputGroupSelector);
