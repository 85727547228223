import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Select } from 'antd';
import { Package } from '../../../common/models';
import './OutputPackageSelector.less';

type Props = {
    packages: Package[];
    isLoadingPackages: boolean;
    value?: string | null;
    onChange?: (value: string | null) => void;
    placeholder?: string;
};

const OutputPackageSelector: React.FC<Props> = ({
    packages,
    isLoadingPackages,
    value,
    onChange,
    placeholder = 'Select output package'
}) => {
    const options = React.useMemo(() => {
        return packages.map(pkg => ({
            value: pkg.id,
            label: pkg.name,
            title: pkg.name
        }));
    }, [packages]);

    return (
        <Select
            className="output-package-selector"
            value={value || undefined}
            onChange={(selectedValue) => onChange?.(selectedValue || null)}
            placeholder={isLoadingPackages ? 'Loading packages...' : placeholder}
            loading={isLoadingPackages}
            options={options}
            showSearch
            filterOption={(input, option) =>
                (option?.label?.toString() || '').toLowerCase().includes(input.toLowerCase())
            }
        />
    );
};

export default observer(OutputPackageSelector);
