import { action, computed, observable, runInAction } from 'mobx';
import {
    AppDefConditionalInputBindingMetadata,
    AppDefConditionalInputBindingMetadataDto,
    AppDefInputBindingMetadata,
    AppDefInputBindingMetadataDto,
    InputBindingMetadataBaseDto,
    TopicMappingTargetItemGroupModel
} from '../types';
import { message } from 'antd';
import { TestProjectService } from '../services';
import TestProjectDashboardStore from './TestProjectDashboardStore';
import { TestProjectTopicMappingModel } from '../models';
import { RulesService } from '../../rules/services';
import { TagModel } from '../../rules/models/TagModel';
import { TagsGroupModel } from '../../rules/models/TagsGroupModel';
import { BindingGroup } from '../../field_bindings/types';
import _ from 'lodash';

export default class TestProjectsWizardStore {
    @observable
    targetInputType: 'Tags' | 'Bindings' | 'ProjectFields' | undefined;

    @observable
    isLoadingInputBindingsMetadata: boolean = false;

    @observable
    isLoadingRuleData: boolean = false;

    @observable
    inputBindingsMetadata: InputBindingMetadataBaseDto[] = [];

    @observable
    targetIotaApplicationId: string | undefined;

    @observable
    targetIotaAppInputGroupId: string | undefined;

    @observable
    mappingDataSource: TopicMappingTargetItemGroupModel[] = [];

    @observable
    topicsToMap: TestProjectTopicMappingModel[] = [];

    @observable
    currentProjectTags: TagModel[] = [];

    @observable
    currentProjectTagGroups: TagsGroupModel[] = [];

    @observable
    fieldBindingGroups: BindingGroup[] = [];

    @observable
    isLoadingFieldBindingGroups: boolean = false;

    @observable
    isSubmittingMapping: boolean = false;

    @computed
    get currentProjectId() {
        return this.dashboardStore.currentProject?.id;
    }

    @computed
    get currentTestProject() {
        return this.dashboardStore.testProject;
    }

    @computed
    get availableTargetInputTypes() {
        if (!this.currentTestProject) {
            return [];
        }

        const availableInputTypes = ['Tags', 'Bindings', 'ProjectFields'];
        return availableInputTypes.filter(inputType => inputType !== this.currentTestProject!.inputsType);
    }

    @computed
    get iotaApplications() {
        return this.inputBindingsMetadata.map(metadata => {
            return {
                id: metadata.applicationId,
                name: metadata.applicationName
            };
        });
    }

    @computed
    get isValidMapping() {
        return this.topicsToMap.some(topic => !!topic.newValue);
    }

    @computed
    get currentApplicationMetadata() {
        return this.inputBindingsMetadata.find(metadata => metadata.applicationId === this.targetIotaApplicationId);
    }

    @computed
    get currentApplicationInputGroups() {
        if (!this.currentApplicationMetadata) {
            return [];
        }

        if (this.currentApplicationMetadata.applicationType === 'ApplicationDefinitionConditional') {
            const appMetadata = this.currentApplicationMetadata as AppDefConditionalInputBindingMetadata;
            return appMetadata.inputGroups.map(ig => {
                return {
                    id: ig.inputGroupId,
                    name: ig.inputGroupName
                };
            });
        }

        return [];
    }

    constructor(
        private service: TestProjectService,
        private rulesService: RulesService,
        private dashboardStore: TestProjectDashboardStore
    ) {}

    @action.bound
    getFriendlyTopicTypeName(bindingType: string) {
        switch (bindingType) {
            case 'Tags':
                return 'Tags';
            case 'Bindings':
                return 'IOTA App Bindings';
            case 'ProjectFields':
                return 'Project fields';
            default:
                return '';
        }
    }

    @action.bound
    resetWizard() {
        this.targetInputType = undefined;
        this.isLoadingInputBindingsMetadata = false;
        this.isLoadingRuleData = false;
        this.inputBindingsMetadata = [];
        this.targetIotaApplicationId = undefined;
        this.targetIotaAppInputGroupId = undefined;
        this.mappingDataSource = [];
        this.topicsToMap = [];
        this.currentProjectTags = [];
        this.currentProjectTagGroups = [];
        this.fieldBindingGroups = [];
        this.isLoadingFieldBindingGroups = false;
    }

    @action.bound
    setTargetInputType(inputType: 'Tags' | 'Bindings' | 'ProjectFields') {
        this.targetInputType = inputType;

        if (inputType === 'Bindings') {
            this.topicsToMap = [];
            this.mappingDataSource = [];
        } else {
            this.setTargetIotaApplicationId(undefined);
            this.prepareMappingData();
        }
    }

    @action.bound
    setTargetIotaApplicationId(applicationId: string | undefined) {
        this.targetIotaApplicationId = applicationId;

        const app = this.inputBindingsMetadata.find(metadata => metadata.applicationId === applicationId);

        if (app && app.applicationType === 'ApplicationDefinitionConditional') {
            this.setTargetIotaAppInputGroupId(undefined);
            this.topicsToMap = [];
            return;
        }

        if (applicationId) {
            this.prepareMappingData();
        }
    }

    @action.bound
    setTargetIotaAppInputGroupId(groupId: string | undefined) {
        this.targetIotaAppInputGroupId = groupId;

        if (groupId) {
            this.prepareMappingData();
        }
    }

    @action.bound
    async submitMapping() {
        if (!this.currentProjectId || !this.currentTestProject || !this.targetInputType) {
            return;
        }

        try {
            this.setIsSubmittingMapping(true);
            const topicMappings = this.topicsToMap
                .filter(topic => topic.newValue)
                .map(topic => {
                    return {
                        topicId: topic.sourceTopic.topicId,
                        newValue: topic.newValue!
                    };
                });

            const result = await this.service.updateTestProjectTopicsType(
                this.currentProjectId!,
                this.currentTestProject.id,
                topicMappings,
                this.targetInputType,
                this.targetIotaApplicationId,
                this.targetIotaAppInputGroupId
            );

            if (result.isOk()) {
                message.success('Mapping submitted successfully');
                this.dashboardStore.loadTestProject();
            } else {
                message.error('Failed to submit mapping');
                console.error(result.error);
            }

            return true;
        } catch (error) {
            message.error('Failed to submit mapping');
            console.error(error);
            return false;
        } finally {
            this.setIsSubmittingMapping(false);
        }
    }

    @action.bound
    async initWizardData() {
        await this.loadProjectInputBindingsMetadata();
        await this.loadProjectRuleData();
        await this.loadProjectFieldBindingsGroups();
    }

    @action.bound
    private setIsSubmittingMapping(isSubmitting: boolean) {
        this.isSubmittingMapping = isSubmitting;
    }

    @action.bound
    private setIsLoadingFieldBindingGroups(isLoading: boolean) {
        this.isLoadingFieldBindingGroups = isLoading;
    }

    @action.bound
    private setIsLoadingInputBindingsMetadata(isLoading: boolean) {
        this.isLoadingInputBindingsMetadata = isLoading;
    }

    @action.bound
    private setIsLoadingRuleData(isLoading: boolean) {
        this.isLoadingRuleData = isLoading;
    }

    @action.bound
    private prepareMappingData() {
        if (!this.currentTestProject) {
            return;
        }

        this.topicsToMap = this.currentTestProject.topics.map(topic => new TestProjectTopicMappingModel(topic));
        this.prepareSourceData();
    }

    @action.bound
    private setInputBindingsMetadata(metadata: InputBindingMetadataBaseDto[]) {
        this.inputBindingsMetadata = metadata;
    }

    @action.bound
    private setProjectFieldBindingGroups(groups: BindingGroup[]) {
        this.fieldBindingGroups = groups;
    }

    @action.bound
    private async loadProjectInputBindingsMetadata() {
        if (!this.currentProjectId) {
            return;
        }

        try {
            this.setIsLoadingInputBindingsMetadata(true);
            const metadata = await this.service.getInputBindings(this.currentProjectId);

            const inputBindingsMetadata = metadata.map(data =>
                data.applicationType === 'ApplicationDefinitionConditional'
                    ? new AppDefConditionalInputBindingMetadata(data as AppDefConditionalInputBindingMetadataDto)
                    : new AppDefInputBindingMetadata(data as AppDefInputBindingMetadataDto)
            );

            this.setInputBindingsMetadata(inputBindingsMetadata);
        } catch (error) {
            message.error('Failed to load project input bindings');
            console.error(error);
        } finally {
            this.setIsLoadingInputBindingsMetadata(false);
        }
    }

    @action.bound
    private async loadProjectRuleData() {
        if (!this.currentProjectId) {
            return;
        }

        try {
            this.setIsLoadingRuleData(true);
            const tags = await this.rulesService.getTagsByProject(this.currentProjectId);
            const tagGroups = await this.rulesService.getTagsGroupsByProject(this.currentProjectId);

            runInAction(() => {
                this.currentProjectTags = tags || [];
                this.currentProjectTagGroups = tagGroups || [];
            });
        } catch (error) {
            message.error('Failed to load project rule data');
            console.error(error);
        } finally {
            this.setIsLoadingRuleData(true);
        }
    }

    @action.bound
    private async loadProjectFieldBindingsGroups() {
        if (!this.currentProjectId) {
            return;
        }

        try {
            this.setIsLoadingFieldBindingGroups(true);
            const groups = await this.service.getProjectFieldGroups(this.currentProjectId);
            this.setProjectFieldBindingGroups(groups);
        } catch (error) {
            message.error('Failed to load project field bindings');
            console.error(error);
        } finally {
            this.setIsLoadingFieldBindingGroups(false);
        }
    }

    @action.bound
    private prepareSourceData() {
        if (!this.targetInputType) {
            return;
        }

        switch (this.targetInputType) {
            case 'Tags':
                this.prepareTagsData();
                break;
            case 'Bindings':
                this.prepareBindingsData();
                break;
            case 'ProjectFields':
                this.prepareProjectFieldsData();
                break;
            default:
                console.log('Unknown target input type');
                break;
        }
    }

    @action.bound
    private prepareTagsData() {
        this.mappingDataSource = this.currentProjectTagGroups.map(tagGroup => {
            const items = this.currentProjectTags
                .filter(tag => tag.groupId === tagGroup.id)
                .map(tag => {
                    return {
                        id: tag.id!,
                        name: tag.name
                    };
                });
            const group = new TopicMappingTargetItemGroupModel(tagGroup.name, items);
            return group;
        });
    }

    @action.bound
    private prepareBindingsData() {
        if (!this.targetIotaApplicationId) {
            return;
        }

        const applicationMetadataBase = this.inputBindingsMetadata.find(
            metadata => metadata.applicationId === this.targetIotaApplicationId
        );
        if (!applicationMetadataBase) {
            return;
        }

        if (
            applicationMetadataBase.applicationType === 'ApplicationDefinitionConditional' &&
            !this.targetIotaAppInputGroupId
        ) {
            return;
        }

        if (applicationMetadataBase.applicationType === 'ApplicationDefinitionConditional') {
            const applicationMetadata = applicationMetadataBase as AppDefConditionalInputBindingMetadata;

            const targetGroup = applicationMetadata.inputGroups.find(
                group => group.inputGroupId === this.targetIotaAppInputGroupId
            );

            if (!targetGroup) {
                return;
            }

            const groupSections = new Set([...targetGroup.inputs.map(input => input.section)]);

            this.mappingDataSource = Array.from(groupSections).map(section => {
                // TODO: Double check bindings if there are multiple inputs
                const items = targetGroup.inputs
                    .filter(input => input.section === section)
                    .map(input => {
                        return {
                            id: input.inputId,
                            name: input.name
                        };
                    });
                return new TopicMappingTargetItemGroupModel(section, items);
            });
        } else {
            const applicationMetadata = applicationMetadataBase as AppDefInputBindingMetadata;
            const sections = new Set([...applicationMetadata.inputs.map(input => input.section)]);

            this.mappingDataSource = Array.from(sections).map(section => {
                const items = applicationMetadata.inputs
                    .filter(input => input.section === section)
                    .map(input => {
                        return {
                            id: input.inputId,
                            name: input.name
                        };
                    });
                return new TopicMappingTargetItemGroupModel(section, items);
            });
        }
    }

    @action.bound
    private prepareProjectFieldsData() {
        let newMappingData: TopicMappingTargetItemGroupModel[] = [];
        for (const group of this.fieldBindingGroups) {
            const fields = _.uniqBy(group.fields, 'inputId');
            const groupSections = group.sections.map(section => {
                const items = fields
                    .filter(field => field.sectionId === section.sectionId)
                    .map(field => {
                        return {
                            id: field.inputId,
                            name: field.name
                        };
                    });
                return new TopicMappingTargetItemGroupModel(`${group.name}/${section.name}`, items);
            });
            newMappingData = [...newMappingData, ...groupSections];
        }

        this.mappingDataSource = newMappingData;
    }
}
