import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Form, Input } from 'antd';
import { DataCrafterTemplatesVisualStore } from '../../stores';
import './DuplicateTemplateDialog.less';

type Props = {
    store: DataCrafterTemplatesVisualStore;
};

const DuplicateTemplateDialog: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (store.templateToDuplicate && store.sourceTemplateName) {
            form.setFieldsValue({
                templateName: `${store.sourceTemplateName} (Copy)`
            });
        }
    }, [form, store.templateToDuplicate, store.sourceTemplateName]);

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const success = await store.duplicateTemplate(values.templateName);
            if (success) {
                form.resetFields();
            }
        } catch (error) {
            // Form validation failed
        }
    };

    const handleCancel = () => {
        form.resetFields();
        store.setTemplateToDuplicate(null);
    };

    return (
        <Modal
            title="Duplicate Template"
            visible={!!store.templateToDuplicate}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={store.isDuplicating}
            okText="Duplicate"
            cancelText="Cancel"
            destroyOnClose
            maskClosable={false}
        >
            <Form form={form} layout="vertical" preserve={false}>
                <Form.Item
                    name="templateName"
                    label="New Template Name"
                    rules={[
                        { required: true, message: 'Please enter a template name' },
                        { whitespace: true, message: 'Template name cannot be empty' }
                    ]}
                >
                    <Input placeholder="Enter template name" autoFocus />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(DuplicateTemplateDialog);
