import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Progress } from 'antd';

type TestProjectProgressBarProps = {
    executedCount: number | undefined;
    totalCount: number | undefined;
};

const TestProjectProgressBar: React.FC<TestProjectProgressBarProps> = ({ executedCount, totalCount }) => {
    return (
        <Progress
            percent={Math.floor(((executedCount ?? 0) / (totalCount ?? 1)) * 100)}
            trailColor="#E4E5ED"
            strokeColor="#273C75"
        />
    );
};

export default observer(TestProjectProgressBar);
