import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { FlexContentContainer } from '../../common/components';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { STORE_PROJECT_FIELD_BINDINGS_PREVIEW } from '../stores/constants';
import { FieldBindingsStores } from '../stores';
import { RulesStores } from '../../rules/stores';
import { TagPreview } from '../../rules/components';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';

type Props = FieldBindingsStores & RulesStores;

export const FieldPreviewPage: React.FC<Props> = ({ ProjectFieldBindingsPreviewUI }) => {
    const lines = !ProjectFieldBindingsPreviewUI!.isLoading ? (
        <TagPreview store={ProjectFieldBindingsPreviewUI!} />
    ) : (
        <LoadingIndicator />
    );

    return (
        <HasPermission permissionClaim={AppPermissions.CanManageProjectFields}>
            <FlexContentContainer content={lines} />
        </HasPermission>
    );
};

export default inject(STORE_PROJECT_FIELD_BINDINGS_PREVIEW)(observer(FieldPreviewPage));
