import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Layout, Button, Collapse, Form, Input, Spin } from 'antd';
import LayoutHeader from '../../../../components/LayoutHeader';
import { Content } from 'antd/lib/layout/layout';
import { Utils } from '../../../common/services/Utils';
import { DataCrafterTemplatesVisualStore, DataCrafterTemplateFormStore } from '../../stores';
import './EditDataCrafterTemplate.less';
import InputPackageColumnsSelector from '../InputPackageColumnsSelector';
import OutputPackageColumnsSelector from '../OutputPackageColumnsSelector';
import { DataCrafterTemplateScript } from '../index';
import { CodeOutlined, LoginOutlined, LogoutOutlined } from '@ant-design/icons';
type Props = {
    store: DataCrafterTemplatesVisualStore;
    formStore: DataCrafterTemplateFormStore;
};

const EditDataCrafterTemplate: React.FC<Props> = ({ store, formStore }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (store.currentProject && store.selectedTemplateId) {
            formStore.loadPackages();
            formStore.initializeWithTemplate(store.selectedTemplateId);
        }
    }, [store, store.currentProject, store.selectedTemplateId, formStore]);

    React.useEffect(() => {
        if (formStore.selectedTemplate) {
            form.setFieldsValue({
                templateName: formStore.selectedTemplate.name
            });
        }
    }, [form, formStore.selectedTemplate]);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            await formStore.updateTemplate(values.templateName);
        } catch (error) {
            // Form validation failed
        }
    };

    return (
        <Layout className="edit-data-crafter-template" style={{ height: '100%', background: 'white' }}>
            <LayoutHeader
                subtitle={Utils.getSubtitle(store.currentProject)}
                helpMessage=""
                title="Edit Data Crafter Template"
                buttons={[
                    <Button
                        key="data-crafter-templates-go-back"
                        data-id="button-go-to-list"
                        className="light"
                        size="large"
                        onClick={() => formStore.navigateToList()}
                    >
                        Go back
                    </Button>,
                    <Button
                        key="data-crafter-templates-submit"
                        data-id="button-submit"
                        type="primary"
                        size="large"
                        onClick={handleSubmit}
                        disabled={!formStore.canSubmit || formStore.isLoadingTemplate}
                    >
                        Update Template
                    </Button>
                ]}
            />
            <Layout>
                <Content style={{ maxHeight: 'calc(100vh - 70px)', overflowY: 'auto' }}>
                    <div style={{ padding: '24px' }}>
                        <Spin spinning={formStore.isLoadingTemplate} tip="Loading template...">
                            <Form form={form} layout="vertical" className="data-crafter-template-form">
                                <Form.Item
                                    name="templateName"
                                    label="Template Name"
                                    rules={[
                                        { required: true, message: 'Please enter a template name' },
                                        { whitespace: true, message: 'Template name cannot be empty' }
                                    ]}
                                >
                                    <Input placeholder="Enter template name" disabled={formStore.isLoadingTemplate} />
                                </Form.Item>

                                <Collapse defaultActiveKey={['1', '2', '3']} bordered={false}>
                                    <Collapse.Panel
                                        header={
                                            <span>
                                                <LoginOutlined /> Step 1: Select Input Package Columns
                                            </span>
                                        }
                                        key="1"
                                        disabled={formStore.isLoadingTemplate}
                                    >
                                        <InputPackageColumnsSelector createStore={formStore} />
                                    </Collapse.Panel>
                                    <Collapse.Panel
                                        header={
                                            <span>
                                                <LogoutOutlined /> Step 2: Configure Output Package Columns
                                            </span>
                                        }
                                        key="2"
                                        disabled={formStore.isLoadingTemplate}
                                    >
                                        <OutputPackageColumnsSelector createStore={formStore} />
                                    </Collapse.Panel>
                                    <Collapse.Panel
                                        header={
                                            <span>
                                                <CodeOutlined /> Step 3: Compile Template Script
                                            </span>
                                        }
                                        key="3"
                                        disabled={formStore.isLoadingTemplate}
                                    >
                                        <DataCrafterTemplateScript store={formStore} />
                                    </Collapse.Panel>
                                </Collapse>
                            </Form>
                        </Spin>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(EditDataCrafterTemplate);
