import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Layout, Button, Collapse, Form, Input } from 'antd';
import LayoutHeader from '../../../../components/LayoutHeader';
import { Content } from 'antd/lib/layout/layout';
import { Utils } from '../../../common/services/Utils';
import { DataCrafterTemplatesVisualStore, DataCrafterTemplateFormStore } from '../../stores';
import './CreateDataCrafterTemplate.less';
import InputPackageColumnsSelector from '../InputPackageColumnsSelector';
import OutputPackageColumnsSelector from '../OutputPackageColumnsSelector';
import { DataCrafterTemplateScript } from '../index';
import { LoginOutlined } from '@ant-design/icons';
import { CodeOutlined } from '@ant-design/icons';
import { LogoutOutlined } from '@ant-design/icons';

type Props = {
    store: DataCrafterTemplatesVisualStore;
    createStore: DataCrafterTemplateFormStore;
};

const CreateDataCrafterTemplate: React.FC<Props> = ({ store, createStore }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (store.currentProject) {
            createStore.loadPackages();
        }
    }, [store, store.currentProject, createStore]);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            await createStore.createTemplate(values.templateName);
        } catch (error) {
            // Form validation failed
        }
    };

    return (
        <Layout className="create-data-crafter-template" style={{ height: '100%', background: 'white' }}>
            <LayoutHeader
                subtitle={Utils.getSubtitle(store.currentProject)}
                helpMessage=""
                title="Create Data Crafter Template"
                buttons={[
                    <Button
                        key="data-crafter-templates-go-back"
                        data-id="button-go-to-list"
                        className="light"
                        size="large"
                        onClick={() => createStore.navigateToList()}
                    >
                        Go back
                    </Button>,
                    <Button
                        key="data-crafter-templates-submit"
                        data-id="button-submit"
                        type="primary"
                        size="large"
                        onClick={handleSubmit}
                        disabled={!createStore.canSubmit}
                    >
                        Create Template
                    </Button>
                ]}
            />
            <Layout>
                <Content style={{ maxHeight: 'calc(100vh - 70px)', overflowY: 'auto' }}>
                    <div style={{ padding: '24px' }}>
                        <Form form={form} layout="vertical" className="data-crafter-template-form">
                            <Form.Item
                                name="templateName"
                                label="Template Name"
                                rules={[
                                    { required: true, message: 'Please enter a template name' },
                                    { whitespace: true, message: 'Template name cannot be empty' }
                                ]}
                            >
                                <Input placeholder="Enter template name" />
                            </Form.Item>

                            <Collapse defaultActiveKey={['1']} bordered={false}>
                                <Collapse.Panel
                                    header={
                                        <span>
                                            <LoginOutlined /> Step 1: Select Input Package Columns
                                        </span>
                                    }
                                    key="1"
                                >
                                    <InputPackageColumnsSelector createStore={createStore} />
                                </Collapse.Panel>
                                <Collapse.Panel
                                    header={
                                        <span>
                                            <LogoutOutlined /> Step 2: Configure Output Package Columns
                                        </span>
                                    }
                                    key="2"
                                >
                                    <OutputPackageColumnsSelector createStore={createStore} />
                                </Collapse.Panel>
                                <Collapse.Panel
                                    header={
                                        <span>
                                            <CodeOutlined /> Step 3: Compile Template Script
                                        </span>
                                    }
                                    key="3"
                                >
                                    <DataCrafterTemplateScript store={createStore} />
                                </Collapse.Panel>
                            </Collapse>
                        </Form>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );

    // TODO: Add section with python script compilation (create dummy endpoint for that)
    // TODO: Add indefinite loading spinner while python script is compiling
    // TODO: Add section with output excel preview (create dummy endpoint for that)
};

export default observer(CreateDataCrafterTemplate);
